import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../core/locale/strings.json";
import pageNotFound from "../../assets/images/page-not-found.svg";
import "./errorHandler.less";

const ErrorPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const goBackToDashboardPage = useCallback(() => {
    navigate("/dashboard");
  }, []);

  return (
    <div className="error-containter">
      <div className="error-content">
        <img
          className="error-image"
          src={pageNotFound}
          alt="Error illustration"
        />
        <div className="error-title">
          {translate(ConstantKey.PAGE_NOT_FOUND)}
        </div>
        <div className="error-message">
          {translate(ConstantKey.PAGE_NOT_FOUND_MSG)}
        </div>
        <Button type={ButtonType.primary} onClick={goBackToDashboardPage}>
          {translate(ConstantKey.GO_BACK_TO_DASHBOARD)}
        </Button>
        <div className="support-email">
          {translate(ConstantKey.NEED_HELP)}
          &nbsp;
          <a href="mailto:support@example.com">
            {translate(ConstantKey.SUPPORT_EMAIL)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
