import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import {
  Button,
  ButtonType,
  Col,
  Row,
} from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../core/locale/strings.json";
import GrowerApi from "../../core/api/growerAPI";
import { useDebounce } from "../../core/hook/useDebounce";
import useForm, { isValidEmail } from "../../core/hook/useForm";
import { type FormState } from "../../core/types/authentication.dto";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import ForgotPasswordFooter from "./forgotPasswordFooter";
import "./forgot-password.less";
export const initialFormState: FormState = {
  email: {
    value: "",
    required: true,
    validate: isValidEmail,
    validateMessage: ConstantKey.PLEASE_ENTER_AN_ACCOUNT_EMAIL,
  },
};
const ForgotPassword = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const [inputEmail, setInputEmail] = useState("");
  const [isUserValid, setIsUserValid] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(false);
  const { formErrors, handleChange } = useForm(initialFormState);

  const continueSendLink = (): void => {
    setLoading(true);
    GrowerApi.checkGrowerInfo(inputEmail)
      .then((res) => {
        if (res.status === 204) {
          setIsUserValid(false);
        } else {
          setIsUserValid(true);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isValid = (input: string): any => {
    handleChange("email", input);
    setIsUserValid(null);
  };
  const isEmailValid = useDebounce(isValid, 3000);

  const onChangeEmal = (event: any): void => {
    setInputEmail(event.target.value);
    isEmailValid(inputEmail);
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={12} prefixCls="forgot-password-sec">
        <div className="forgot-password-container">
          <div className="flex-grow-0">
            <img
              className="forgot-password-field-logo flex-grow-0"
              src={synMyFieldLogo}
              alt="Syngenta Myfield Logo"
            />
          </div>
          <div className="flex-grow-0">
            <p className="forgot-password-desc">
              {translate(ConstantKey.RECOVER_PASSWORD)}
            </p>
            <p className="forgot-password-msg mf-mb-0">
              {translate(ConstantKey.FORGOT_PASSWORD_MSG)}
            </p>
          </div>
          <div className="forgot-password-form flex-grow-0">
            <div className="mf-width-100 mf-pr-10 mf-pl-10">
              <p className="forgot-password-label mf-pt-10">
                {translate(ConstantKey.YOUR_EMAIL)}
              </p>
              <Input
                type="default"
                placeholder={translate(ConstantKey.ENTER_ACCOUNT_EMAIL)}
                size="middle"
                value={inputEmail}
                className="mf-accounts-input"
                onChange={onChangeEmal}
              />
              {(formErrors?.email ?? isUserValid === false) && (
                <p className="mf-field-error-msg">
                  {translate(ConstantKey.PLEASE_ENTER_AN_ACCOUNT_EMAIL)}
                </p>
              )}
            </div>

            <Button
              loading={loading}
              type={ButtonType.primary}
              data-testid="sign-in-continue-btn"
              key="loginBtn"
              className="forgot-password-button mf-mt-10"
              onClick={continueSendLink}
              disabled={
                !(inputEmail && !formErrors?.email) || isUserValid === false
              }
            >
              {translate(ConstantKey.SEND_LINK)}
            </Button>
          </div>
          <ForgotPasswordFooter />
        </div>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={12}
        prefixCls="forgot-password-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/forgot-password.png)`,
        }}
      ></Col>
    </Row>
  );
};

export default ForgotPassword;
