import { useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { jsPDF } from "jspdf";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import ConstantKey from "../../core/locale/strings.json";
import type { ApplicationState } from "../../core/redux/reducers";
import { setPrecommitmenDetails } from "../../core/redux/reducers/precommitmentsSlice";
import { isMobileView } from "../../core/utils/common-function";
import { PRE_COMMITMENTS_CREATION } from "../../core/constants/url";
import preCommitmentDateIcon from "../../assets/icons/pre_commitmet_date_icon.svg";
import voucherIcon from "../../assets/icons/voucherIcon.svg";
import arrowDropDown from "../../assets/icons/arrow_drop_down_24px.svg";
import errorOutlineIcon from "../../assets/icons/error_outline.svg";
import "./precommitments.less";

interface PrecommitmentSubData {
  material_name: string;
  plan_created_date: string;
  item_planned_qty: number;
}
const Precommitment = (): JSX.Element => {
  const uid = useId();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [apiFailed, setApiFailed] = useState(null as null | boolean);
  const [loader, setLoader] = useState(false as boolean);
  const PrecommitmentState = useSelector(
    (state: ApplicationState) => state.PrecommitmentState
  );

  useEffect(() => {
    if (PrecommitmentState?.precommitmentDetails === null) {
      setLoader(true);
      ProductTemplateApi.getPreCommitments()
        .then((response) => {
          if (response?.status === 200) {
            let convertedDateData: any = null;
            let convertedVoucherIdData: any = null;
            if (response?.data?.planCreateDate)
              convertedDateData = convertDateData(
                response?.data?.planCreateDate
              );
            if (response?.data?.voucherId)
              convertedVoucherIdData = convertVoucherData(
                response?.data?.voucherId
              );
            dispatch(
              setPrecommitmenDetails({
                planCreateDate: convertedDateData,
                voucherId: convertedVoucherIdData,
              })
            );
            setApiFailed(false);
          }
        })
        .catch(() => {
          setApiFailed(true);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, []);

  const convertDateData = (apiResponse: any): any => {
    let displayData = {};
    const isMobile = isMobileView();
    Object.keys(apiResponse).forEach((responseData: any) => {
      displayData = {
        ...displayData,
        [responseData]: {
          info: apiResponse[responseData],
          selected: !isMobile,
        },
      };
    });
    return displayData;
  };
  const convertVoucherData = (apiResponse: any): any => {
    let displayData = {};
    const isMobile = isMobileView();
    Object.keys(apiResponse).forEach((responseData: any) => {
      displayData = {
        ...displayData,
        [responseData]: {
          info: apiResponse[responseData],
          selected: !isMobile,
          createdDate: apiResponse[responseData][0].plan_created_date,
        },
      };
    });
    return displayData;
  };
  const onClickCreateButton = (): void => {
    window.open(PRE_COMMITMENTS_CREATION, "_blank", "noopener");
  };
  const onClickSeeMoreDate = (item: string): void => {
    const precommitments =
      PrecommitmentState?.precommitmentDetails?.planCreateDate;
    const selectedItemChanges = {
      ...precommitments,
      [item]: {
        ...precommitments[item],
        selected: !precommitments[item].selected,
      },
    };
    dispatch(
      setPrecommitmenDetails({
        ...PrecommitmentState?.precommitmentDetails,
        planCreateDate: selectedItemChanges,
      })
    );
  };
  const onClickSeeMoreVoucher = (item: string): void => {
    const precommitments = PrecommitmentState?.precommitmentDetails?.voucherId;
    const selectedItemChanges = {
      ...precommitments,
      [item]: {
        ...precommitments[item],
        selected: !precommitments[item].selected,
      },
    };
    dispatch(
      setPrecommitmenDetails({
        ...PrecommitmentState?.precommitmentDetails,
        voucherId: selectedItemChanges,
      })
    );
  };
  const onClickDownloadPDF = (): void => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    doc?.text?.(translate(ConstantKey.COMING_SOON), 10, 10);
    doc?.save?.("document.pdf");
  };
  const displaySubItems = (item: PrecommitmentSubData): any => {
    return (
      <div className="mf-mt-10" key={uid}>
        <div className="precommitment-bold">{item.material_name}</div>
        {item.plan_created_date}
        <div></div>
        <div className="precommitment-color-green">
          {item.item_planned_qty} {translate(ConstantKey.UNITS)}
        </div>
      </div>
    );
  };
  return (
    <div className=" precommitments-body">
      {loader && PrecommitmentState?.precommitmentDetails === null && (
        <div className="mf-loader"></div>
      )}
      <div className="precommit-title">
        <div>
          <div className="precommit-maintitle">
            {translate(ConstantKey.PRE_COMMITTED_OFFERS)}
          </div>
          <div className="precommit-subtitle">
            {translate(ConstantKey.PRE_COMMITMENTS_DECLARATION_PRODUCTS)}
          </div>
        </div>
        <Button
          type={ButtonType.primary}
          className="precommitments-create-button"
          onClick={onClickCreateButton}
        >
          {translate(ConstantKey.CREATE_PRE_COMMITMENTS)}
        </Button>
      </div>
      <div className="precommitment-margin-top">
        {PrecommitmentState?.precommitmentDetails?.voucherId &&
          Object.keys(PrecommitmentState?.precommitmentDetails?.voucherId)?.map(
            (voucher: string) => {
              return (
                <div className="precommitment-details-content" key={uid}>
                  <div className="precommitment-details-top-section">
                    <div className="precommitment-details-text">
                      <div className="precommitment-bold precommit-pre-commit-flex">
                        <span className="">
                          <img
                            className="precommitment-icon"
                            src={voucherIcon}
                            alt="dateicon"
                          />
                          <span className="mf-ml-5 precommit-pre-commit">
                            {translate(ConstantKey.VOUCHER)}
                          </span>
                          <span className="precommit-vocher-date">
                            <span className=" precommitment-gray-color">
                              {translate(ConstantKey.CREATED_DATE)}:
                            </span>
                            <span className="precommitment-color-green mf-ml-5">
                              {
                                PrecommitmentState?.precommitmentDetails
                                  ?.voucherId[voucher]?.createdDate
                              }
                            </span>
                          </span>
                        </span>

                        <span className="precommitment-grid-spam">
                          <span>{translate(ConstantKey.ID)}:</span>
                          <span className="precommit-id">{voucher}</span>
                        </span>
                      </div>
                    </div>
                    <div>
                      <Button
                        type={ButtonType.primary}
                        className="precommitment-view-document-button"
                        onClick={onClickDownloadPDF}
                      >
                        {translate(ConstantKey.VIEW_DOCUMENT)}
                      </Button>
                      <div className="precommitment-see-more">
                        <button
                          className="mf-cursor-pointer sidebar_button"
                          onClick={() => {
                            onClickSeeMoreVoucher(voucher);
                          }}
                        >
                          {translate(ConstantKey.SEE_MORE)}
                        </button>{" "}
                        <img src={arrowDropDown} alt="arowicon" />
                      </div>
                    </div>
                  </div>
                  {PrecommitmentState?.precommitmentDetails?.voucherId[voucher]
                    ?.selected && (
                    <div className="precommitment-details-subcontent">
                      {PrecommitmentState?.precommitmentDetails?.voucherId[
                        voucher
                      ]?.info.map((voucherData: PrecommitmentSubData) =>
                        displaySubItems(voucherData)
                      )}
                    </div>
                  )}
                </div>
              );
            }
          )}
        {PrecommitmentState?.precommitmentDetails?.planCreateDate &&
          Object.keys(
            PrecommitmentState?.precommitmentDetails?.planCreateDate
          )?.map((date: string) => {
            return (
              <div className="precommitment-details-content " key={uid}>
                <div className="precommitment-details-top-section">
                  <div className="precommitment-details-text">
                    <div className="precommitment-bold">
                      <span className="precommit-pre-icon">
                        <img
                          className="precommitment-icon"
                          src={preCommitmentDateIcon}
                          alt="dateicon"
                        />
                        <span className="mf-ml-5 precommit-pre-commit">
                          {translate(ConstantKey.PRE_COMMITED)}
                        </span>
                      </span>
                      <span className="precommitment-create-date">
                        <span className="mf-ml-10 precommitment-gray-color">
                          {translate(ConstantKey.CREATED_DATE)}:
                        </span>
                        <span className="precommitment-color-green mf-ml-5">
                          {date}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <Button
                      type={ButtonType.primary}
                      className="precommitment-view-document-button"
                      onClick={onClickDownloadPDF}
                    >
                      {translate(ConstantKey.VIEW_DOCUMENT)}
                    </Button>
                    <div className="precommitment-see-more">
                      <button
                        className="mf-cursor-pointer sidebar_button"
                        onClick={() => {
                          onClickSeeMoreDate(date);
                        }}
                      >
                        {translate(ConstantKey.SEE_MORE)}
                      </button>{" "}
                      <img src={arrowDropDown} alt="arowicon" />
                    </div>
                  </div>
                </div>
                {PrecommitmentState?.precommitmentDetails?.planCreateDate[date]
                  ?.selected && (
                  <div className="precommitment-details-subcontent">
                    {PrecommitmentState?.precommitmentDetails?.planCreateDate[
                      date
                    ]?.info.map((dateData: PrecommitmentSubData) =>
                      displaySubItems(dateData)
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {apiFailed && (
        <div className="precommitment-error-msg-body">
          <img
            src={errorOutlineIcon}
            className="precommit-error-img"
            alt="erroricon"
          />
          <div>
            <span className="precommitment-spam-msg">
              {translate(ConstantKey.PRECOMMITMENTS_ERROR_MSG)}
            </span>
            <span className="precommitment-spam-comment">
              {translate(ConstantKey.PRECOMMITMENTS_ERROR_COMMENT)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default Precommitment;
