export const AuthenticationOptions = {
  redirectUri: process.env.REACT_APP_REDIRECT_URL ?? "",
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID ?? "",
};

export interface IAuthTokenRequestDTO {
  readonly grant_type: "authorization_code";
  readonly code: string;
  readonly redirect_uri: string;
  readonly client_id: string;
}

export interface IAuthTokenResponseDTO {
  readonly access_token: string;
  readonly refresh_token: string;
}

export const defaultAuthRequestParams: IAuthTokenRequestDTO = {
  grant_type: "authorization_code",
  code: "",
  redirect_uri: AuthenticationOptions.redirectUri,
  client_id: AuthenticationOptions.clientId,
};

export interface SplashScreenDto {
  showInitialLoader?: boolean;
}

export interface SignInReducerActionDTO {
  type: SignInReduceActionTypeDTO;
  payload: any;
}

export enum SignInReduceActionTypeDTO {
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
}

export interface SignUpDTO {
  email: FormField;
  password: FormField;
  first_name: FormField;
  last_name: FormField;
  job_title: FormField;
  country_code: FormField;
  phone: FormField;
  tcAccept: FormField;
  pnAccept: FormField;
  locale: FormField;
}

export interface SignUpFormStateDTO {
  email: FormField;
  password: FormField;
  first_name: FormField;
  last_name: FormField;
  job_title?: FormField;
  country_code: FormField;
  phone?: FormField;
  tcAccept: FormField;
  pnAccept: FormField;
  locale: FormField;
}

export interface SignUpReducerActionDTO {
  type: SignUpReduceActionTypeDTO;
  payload: any;
}

export enum SignUpReduceActionTypeDTO {
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
  JOB_TITLE = "JOB_TITLE",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  COUNTRY_CODE = "COUNTRY_CODE",
  PHONE = "PHONE",
  TC_ACCEPT = "TC_ACCEPT",
  PN_ACCEPT = "PN_ACCEPT",
  LOCALE = "LOCALE",
  RESET = "RESET",
}

export interface SignInPayloadDTO {
  email: string;
  password: string;
  grant_type: string;
}

export interface PasswordStrengthTypeDTO {
  color: string;
  message: string;
}

export interface PasswordStrengthDTO {
  grey: PasswordStrengthTypeDTO;
  red: PasswordStrengthTypeDTO;
  yellow: PasswordStrengthTypeDTO;
  green: PasswordStrengthTypeDTO;
}

export interface FormField {
  value: any;
  required?: boolean;
  requiredErrorMessage?: string;
  validate?: (value: string) => boolean;
  validateMessage?: string;
}

export interface FormState {
  email: FormField;
  password?: FormField;
}

export interface Action {
  type: string;
  payload: string;
}
