import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import {
  Button,
  ButtonType,
  notification as Notification,
} from "syngenta-digital-cropwise-react-ui-kit";
import type { ApplicationState } from "../../core/redux/reducers";
import { setRefreshUserData } from "../../core/redux/reducers/accountProfileSlice";
import MyFieldLogo from "../../assets/icons/myfield-icon.svg";
import EmailVerificationIcon from "../../assets/images/email_verification_image.svg";
import CustomMenu from "../../shared/components/menu";
import GrowerApi from "../../core/api/growerAPI";
import ConstantKey from "../../core/locale/strings.json";
import accountCircle from "../../assets/icons/account_circle_white.svg";
import { getCountryCodeFromBrowser } from "../../core/utils/common-function";
import RenderLanguageLabel from "../../shared/components/menu/RenderLanguageLabel";
import "./email-verification.less";

const EmailVerification = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const handleResendEmail = (): void => {
    if (profileDataSelector?.profileData?.email)
      GrowerApi.reSendEmail(profileDataSelector?.profileData?.email)
        .then((res: any) => {
          if (res?.status === 200) {
            localStorage.setItem("email-verification-gotit", `${new Date()}`);
            Notification.success({
              message: "",
              description: (
                <div>
                  <div className="email-verification-popup">
                    {res?.data?.message}
                  </div>
                  <div>
                    {translate(ConstantKey.PLS_ACCESS_YOUR_EAMIL_ACCOUNT)}
                  </div>
                </div>
              ),
              placement: "topRight",
            });
          } else if (res?.status === 403)
            Notification.success({
              message: res?.data?.eprrorMessage,
              description: "",
              placement: "topRight",
            });
        })
        .catch((error: any) => {
          Notification.error({
            message: error?.cause?.data?.errorMessage,
            description: "",
            placement: "topRight",
          });
        });
  };
  const onClickEnterMyField = (): void => {
    navigate("/dashboard");
    dispatch(setRefreshUserData(true));
  };
  return (
    <div className="email-expired-container">
      <div className="email-expired-content">
        <img
          src={MyFieldLogo}
          className="email-verification-icon"
          alt="myfieldlogo"
        />
        <img
          src={EmailVerificationIcon}
          className="email-verification-image"
          alt="emailIcons"
        />
        <h2 className="email-verification-title">
          {translate(ConstantKey.VERIFY_YOUR_EMAIL)}
        </h2>
        <span className="email-verification-desc">
          {translate(ConstantKey.EMAIL_VERIFY_MSG)}
        </span>
        <Button
          type={ButtonType.outline}
          className="email-verification-outline-btn"
          onClick={handleResendEmail}
        >
          {translate(ConstantKey.RESEND_EMAIL)}
        </Button>
        <span className="email-verification-msg">
          {translate(ConstantKey.HAVE_YOU_CONFIRMED_YOUR_EMAIL)}
        </span>
        <Button
          type={ButtonType.primary}
          className="email-verification-outline-btn"
          onClick={onClickEnterMyField}
        >
          <img src={accountCircle} className="mf-mr-10" alt="account circle" />
          {translate(ConstantKey.ENTER_MYFIELD)}
        </Button>
        <div className="email-footer flex-grow-1">
          <Link
            to={`/legal-docs?country=${getCountryCodeFromBrowser()}&tab=TC`}
            className="mf-mr-10 email-terms-btn"
            target="_blank"
          >
            {translate(ConstantKey.TERMS_AND_CONDITIONS)}
          </Link>
          <CustomMenu
            disabledOverflow
            isUsingDefaultOnSelect
            customLanguageLabel={RenderLanguageLabel}
            className="lng-selection-dropdown"
          />
        </div>
      </div>
    </div>
  );
};
export default EmailVerification;
