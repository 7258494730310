import { useTranslation } from "react-i18next";
import ConstantKey from "../../../core/locale/strings.json";
import "./passwordTooltip.less";
const PasswordTooltip = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <div className="tooltip-style">
      <span>{translate(ConstantKey.PASSWORD_STATUS)}</span>
      <span>&#x2022; {translate(ConstantKey.AT_LEAST_CHARACTERS)}</span>
      <span>&#x2022; {translate(ConstantKey.ONE_LOWERCASE_LETTER)}</span>
      <span>&#x2022; {translate(ConstantKey.ONE_UPPERCASE_LETTER)}</span>
      <span>&#x2022; {translate(ConstantKey.ONE_NUMBER)}</span>
      <span>&#x2022; {translate(ConstantKey.ONE_SPECIAL_CHARACTER)}</span>
    </div>
  );
};
export default PasswordTooltip;
