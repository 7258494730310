import { useCallback, useEffect, useReducer, useState } from "react";
import {
  ButtonType,
  Button,
  Progress,
  Row,
  Col,
} from "syngenta-digital-cropwise-react-ui-kit";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { SignUpReduceActionTypeDTO } from "../../core/types/authentication.dto";
import ConstantKey from "../../core/locale/strings.json";
import { PASSWORD_STRENGTH_INFO } from "../../core/constants/constant";
import useForm from "../../core/hook/useForm";

import CustomMenu from "../../shared/components/menu";
import RenderLanguageLabel from "../../shared/components/menu/RenderLanguageLabel";

import { initialState, signUpReducer } from "./signUpReducer";

import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";

const SignUpPassword = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [state, dispatch] = useReducer(signUpReducer, initialState);
  const [strengthPercent, setStrengthPercent] = useState(0);
  const [passwordStrength, setPasswordStrength] = useState(
    PASSWORD_STRENGTH_INFO.grey
  );

  const { formErrors, handleChange } = useForm(initialState);

  useEffect(() => {
    if (location?.state?.email.value) {
      dispatch({
        type: SignUpReduceActionTypeDTO.EMAIL,
        payload: location.state.email.value,
      });
    }
  }, [location?.state?.email]);

  const validatePassword = useCallback((event: any): string => {
    const password = event.target.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    handleChange("password", password);
    dispatch({
      type: SignUpReduceActionTypeDTO.PASSWORD,
      payload: password,
    });
    if (password.length >= 8) {
      if (hasUpperCase && hasNumber) {
        setStrengthPercent(100);
        setPasswordStrength(PASSWORD_STRENGTH_INFO.green);
        return translate(ConstantKey.STRONG);
      }
      if (hasUpperCase || hasNumber) {
        setStrengthPercent(75);
        setPasswordStrength(PASSWORD_STRENGTH_INFO.yellow);
        return translate(ConstantKey.FAIR);
      }
      setStrengthPercent(50);
      setPasswordStrength(PASSWORD_STRENGTH_INFO.red);
      return translate(ConstantKey.WEAK);
    } else {
      if (password.length === 0) {
        setStrengthPercent(0);
      } else {
        setStrengthPercent(30);
      }
      setPasswordStrength(PASSWORD_STRENGTH_INFO.grey);
      return translate(ConstantKey.TOO_SHORT);
    }
  }, []);

  const continueThirdStep = useCallback(() => {
    if (state.email.value && state.password.value) {
      navigate("/signup-basic-info", {
        state: {
          email: { value: state.email.value },
          password: { value: state.password.value },
        },
      });
    }
  }, [navigate, state]);

  const signUpEmailOnChange = useCallback((event: any) => {
    handleChange("email", event.target.value);
    dispatch({
      type: SignUpReduceActionTypeDTO.EMAIL,
      payload: event.target.value,
    });
  }, []);

  return (
    <Row prefixCls="signup-page">
      <Col xs={24} sm={24} md={12} prefixCls="sign-up-sec">
        <div className="sign-up-container">
          <div className="flex-grow-0">
            <img
              className="syn-field-logo"
              src={synMyFieldLogo}
              alt="Syngenta Myfield Logo"
            />
          </div>
          <div className="flex-grow-0">
            <p className="sign-up-desc">
              {translate(ConstantKey.CREATE_MYFIELD_ACCOUNT)}
            </p>
          </div>
          <div className="sign-up-sub-desc flex-grow-0">
            {translate(ConstantKey.SIGN_UP_DESC)}
          </div>
          <div className="sign-up-form flex-grow-0">
            <div className="mf-width-100 mf-pr-10 mf-pl-10">
              <p className="select-label mf-pt-10">
                {translate(ConstantKey.YOUR_EMAIL)}
              </p>
              <Input
                type="default"
                className="mf-accounts-input"
                data-testid="sign-up-email"
                placeholder={translate(ConstantKey.ENTER_YOUR_EMAIL)}
                size="large"
                autoComplete="off"
                disabled={true}
                value={state.email.value}
                onChange={signUpEmailOnChange}
              />
              {formErrors?.email && (
                <p
                  className="mf-field-error-msg"
                  data-testid="signup-form-error-email-step-2"
                >
                  {formErrors?.email}
                </p>
              )}
            </div>
            <div className="mf-width-100 mf-pr-10 mf-pl-10 password-container">
              <div className="password-label-container">
                <p className="select-label mf-pt-10">
                  {translate(ConstantKey.PASSWORD)}
                </p>
                <div style={{ color: passwordStrength.color }}>
                  {translate(passwordStrength.message)}
                </div>
              </div>
              <Input.Password
                autoComplete="off"
                type="default"
                data-testid="sign-up-password"
                placeholder={translate(ConstantKey.ENTER_PASSWORD)}
                size="large"
                value={state.password.value}
                className="mf-accounts-input password-input"
                onChange={validatePassword}
              />
              {formErrors?.password && (
                <p
                  className="mf-field-error-msg"
                  data-testid="signUp-form-error-password"
                >
                  {formErrors?.password}
                </p>
              )}
              <Progress
                className="password-progress-bar"
                data-testid="password-progress-bar"
                gapDegree={0}
                percent={strengthPercent}
                status="success"
                strokeColor={passwordStrength.color}
                strokeLinecap="round"
                strokeWidth={6}
                type="line"
                showInfo={false}
              />
            </div>
            <Button
              type={ButtonType.info}
              size="large"
              data-testid="sign-up-third-step-continue"
              className={`sign-up-btn ${
                !(state.email.value && state.password.value)
                  ? "sign-up-btn-disabled"
                  : ""
              }`}
              key="SignUpBtn"
              onClick={continueThirdStep}
              disabled={!(state.email.value && state.password.value)}
            >
              {translate(ConstantKey.CONTINUE)}
            </Button>
          </div>
          <div className="lng-options flex-grow-1">
            <CustomMenu
              isUsingDefaultOnSelect
              customLanguageLabel={RenderLanguageLabel}
              className="lng-selection-dropdown"
              disabledOverflow
            />
          </div>
        </div>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={12}
        prefixCls="farm-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/farm-image-login.png)`,
        }}
      ></Col>
    </Row>
  );
};

export default SignUpPassword;
