import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonType,
  Input,
  notification,
} from "syngenta-digital-cropwise-react-ui-kit";
import GrowerApi from "../../core/api/growerAPI";
import ConstantKey from "../../core/locale/strings.json";
import MyFieldLogo from "../../assets/icons/myfield-icon.svg";
import accountCircle from "../../assets/icons/account_circle.svg";
import MyFieldLogoPeage from "../../assets/images/email-expired-page.svg";
import CustomMenu from "../../shared/components/menu";
import { getCountryCodeFromBrowser } from "../../core/utils/common-function";
import RenderLanguageLabel from "../../shared/components/menu/RenderLanguageLabel";
import "./email-expired.less";

const EmailExpiredPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");

  const handleResendEmail = (): void => {
    GrowerApi.reSendEmail(email)
      .then(() => {
        navigate("/signin");
        notification.success({
          message: "",
          description: (
            <div>
              <div className="email-verification-popup">
                {translate(ConstantKey.VERIFY_YOU_EMAIL_SEND_SUCESSFULLY)}
              </div>
              <div>{translate(ConstantKey.PLS_ACCESS_YOUR_EAMIL_ACCOUNT)}</div>
            </div>
          ),
          placement: "topRight",
        });
      })
      .catch((error: any) => {
        if (error?.cause?.data?.status === 404)
          notification.error({
            message: translate(ConstantKey.EMAIL_NOT_FOUND),
            description: translate(ConstantKey.EMAIL_NOT_FOUND_DESCRIPTION),
            placement: "topRight",
          });
        else
          notification.error({
            message: error?.cause?.data?.errorMessage,
            description: "",
            placement: "topRight",
          });
      });
  };
  const onClickEnterMyField = (): void => {
    navigate("/signin");
  };
  return (
    <div className="email-expired-container">
      <div className="email-expired-content">
        <img
          src={MyFieldLogo}
          className="email-myfiled-icon"
          alt="myfieldlogo"
        />
        <div className="illustration">
          <img
            src={MyFieldLogoPeage}
            className="email-illustration-img"
            alt="emailimage"
          />
        </div>
        <h2 className="email-expired-title ">
          {translate(ConstantKey.EMAIL_LINK_EXPIRED)}
        </h2>
        <p className="email-expired-msg">
          {translate(ConstantKey.EMAIL_LINK_DESCRIPTION_MSG)}
        </p>
        <div className="form-group mf-width-100">
          <label htmlFor="email" className="email-label">
            {translate(ConstantKey.YOUR_EMAIL)}
          </label>
          <Input
            type="email"
            id="email"
            className="email-input"
            placeholder={translate(ConstantKey.ENTER_YOUR_ACCOUTN_EMAIL)}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <Button
          type={ButtonType.primary}
          className="resend-button"
          onClick={handleResendEmail}
        >
          {translate(ConstantKey.RESEND_EMAIL)}
        </Button>
        <hr className="email-hr" />
        <p>{translate(ConstantKey.HAVE_YOU_CONFIRMED_YOUR_EMAIL)}</p>
        <Button
          type={ButtonType.outline}
          className="enter-button"
          onClick={onClickEnterMyField}
        >
          <img src={accountCircle} className="mf-mr-10" alt="account circle" />
          {translate(ConstantKey.ENTER_MYFIELD)}
        </Button>
        <div className="email-support flex-grow-0">
          {translate(ConstantKey.NEED_HELP)}
          &nbsp;
          <a href="mailto:support@example.com" className="email-support-color">
            {translate(ConstantKey.SUPPORT_EMAIL)}
          </a>
        </div>
        <div className="email-footer flex-grow-1">
          <Link
            to={`/legal-docs?country=${getCountryCodeFromBrowser()}&tab=TC`}
            className="mf-mr-10 email-terms-btn"
            target="_blank"
          >
            {translate(ConstantKey.TERMS_AND_CONDITIONS)}
          </Link>
          <CustomMenu
            disabledOverflow
            isUsingDefaultOnSelect
            customLanguageLabel={RenderLanguageLabel}
            className="lng-selection-dropdown email-terms-btn"
          />
        </div>
      </div>
    </div>
  );
};

export default EmailExpiredPage;
