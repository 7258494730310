import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../core/locale/strings.json";
import commingsoonicon from "../../assets/images/commingsoon.svg";
import "./errorHandler.less";

const CommingSoon = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const goBackToDashboardPage = useCallback(() => {
    navigate("/dashboard");
  }, []);

  return (
    <div className="error-containter">
      <div className="error-content">
        <img className="error-image" src={commingsoonicon} alt="Error screen" />
        <div className="error-title">{translate(ConstantKey.COMING_SOON)}</div>
        <div className="error-message">
          {translate(ConstantKey.COMMING_SOON_MSG)}
        </div>
        <Button type={ButtonType.primary} onClick={goBackToDashboardPage}>
          {translate(ConstantKey.GO_BACK_TO_DASHBOARD)}
        </Button>
        <div className="support-email">
          {translate(ConstantKey.NEED_HELP)}
          &nbsp;
          <a href="mailto:support@example.com">
            {translate(ConstantKey.SUPPORT_EMAIL)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;
