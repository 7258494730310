import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import { setRefreshUserData } from "../../core/redux/reducers/accountProfileSlice";
import ConstantKey from "../../core/locale/strings.json";
import somethingwentwrong from "../../assets/images/something-went-wrong.svg";
import "./errorHandler.less";
const ErrorPage = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { t: translate } = useTranslation();

  const goBackToDashboardPage = useCallback(() => {
    dispatch(setRefreshUserData(true));
    navigate("/dashboard");
    window.location.reload();
  }, []);
  return (
    <div className="error-containter">
      <div className="error-content">
        <img
          className="error-image"
          src={somethingwentwrong}
          alt="Error illustration"
        />
        <div className="error-title">
          {translate(ConstantKey.SOMETHING_WENT_WRONG)}
        </div>
        <div className="error-message">
          {location?.state?.errorMsg && (
            <span data-testid="error-apidown">{location?.state?.errorMsg}</span>
          )}
          {translate(ConstantKey.PLEASE_TRY_AFTER_SOME_TIME)}
        </div>
        <Button type={ButtonType.primary} onClick={goBackToDashboardPage}>
          {translate(ConstantKey.GO_BACK_TO_DASHBOARD)}
        </Button>
        <div className="support-email">
          {translate(ConstantKey.NEED_HELP)}
          &nbsp;
          <a href="mailto:support@example.com">
            {translate(ConstantKey.SUPPORT_EMAIL)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
