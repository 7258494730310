import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  AntdConfigProvider,
  Button,
  ButtonType,
} from "syngenta-digital-cropwise-react-ui-kit";
import moment from "moment";
import type { ApplicationState } from "../../core/redux/reducers";
import ConstantKey from "../../core/locale/strings.json";
import ForeCastApi from "../../core/api/forecastApi";
import {
  setForcastWeeklyData,
  setForecastHourlyTodayData,
} from "../../core/redux/reducers/forecastSlice";
import wind from "../../assets/icons/wind.svg";
import rain from "../../assets/icons/rain.svg";
import humidity from "../../assets/icons/humidity.svg";
import soilmoisture from "../../assets/icons/soilmoisture.svg";

import {
  getDirectionIcons,
  getIconSVGFile,
  getHourlyForeCastIcon,
} from "../../core/utils/forecastUtils";
import "./forecasthome.less";

interface DailyData {
  DATE_TIMESTAMP: Date;
  Date: string;
  Day: string;
  Month: string;
  PictoCode_Daily: string;
  TempSurface_DailyAvg: string;
  TempSurface_DailyMax: string;
  TempSurface_DailyMin: string;
}

const ForeCastHome = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { t: translate } = useTranslation();
  const Forecastdata = useSelector((state: ApplicationState) => state.forecast);
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  const [forecastWeekly, setForecastWeekly] = useState([]);

  useEffect(() => {
    if (
      profileDataSelector?.profileData?.countryCode &&
      profileDataSelector?.defaultPropertyCoordinates?.lat &&
      profileDataSelector?.defaultPropertyCoordinates?.lng
    ) {
      ForeCastApi.getForecastWeeklyData(
        profileDataSelector?.defaultPropertyCoordinates?.lat,
        profileDataSelector?.defaultPropertyCoordinates?.lng,
        profileDataSelector.profileData?.countryCode
      )
        .then((response) => {
          dispatch(setForcastWeeklyData(response?.data));
        })
        .catch(() => {});
      ForeCastApi.getForecastHourlyTodayData(
        profileDataSelector?.defaultPropertyCoordinates?.lat,
        profileDataSelector?.defaultPropertyCoordinates?.lng,
        profileDataSelector.profileData?.countryCode
      )
        .then((response) => {
          dispatch(setForecastHourlyTodayData(response?.data));
        })
        .catch(() => {});
    }
  }, [
    profileDataSelector?.profileData,
    profileDataSelector?.defaultPropertyCoordinates,
  ]);

  useEffect(() => {
    if (
      Forecastdata?.forecastWeeklyData &&
      Forecastdata?.forecastHourlyTodayData
    ) {
      let weeklyInfo = Forecastdata?.forecastWeeklyData?.map(
        (weeklyData: any) => {
          if (
            weeklyData.Date === Forecastdata?.forecastHourlyTodayData[0]?.Date
          ) {
            return {
              ...weeklyData,
              ...Forecastdata?.forecastHourlyTodayData[0],
            };
          } else return weeklyData;
        }
      );
      weeklyInfo = convertData(weeklyInfo);
      setForecastWeekly(weeklyInfo);
    }
  }, [Forecastdata]);

  const convertData = (data: any): any[] => {
    let modifiedData: any = [];
    data.forEach((ele: any) => {
      let modifiedObj: any = {};
      Object.keys(ele).forEach((obj: string) => {
        modifiedObj = { ...modifiedObj, [obj]: ele[obj] };
      });
      modifiedData.push(modifiedObj);
    });
    modifiedData = modifiedData.sort(
      (a: DailyData, b: DailyData) =>
        new Date(a.DATE_TIMESTAMP).valueOf() -
        new Date(b.DATE_TIMESTAMP).valueOf()
    );

    modifiedData = modifiedData.map((ele: DailyData) => {
      if (new Date(ele.DATE_TIMESTAMP).getDate() === new Date().getDate())
        ele = { ...ele, Day: ConstantKey.TODAY };
      else if (
        new Date(ele.DATE_TIMESTAMP).getDate() ===
        new Date().getDate() - 1
      )
        ele = { ...ele, Day: ConstantKey.YESTERDAY };

      return ele;
    });
    return modifiedData;
  };
  const getTime = (date: string): string => {
    const time = date.split(" ")[1].slice(0, 5);
    return time;
  };
  const displayDialyReport = (data: any): JSX.Element => {
    if (data.Day !== ConstantKey.TODAY)
      return (
        <AntdConfigProvider>
          <div className="weatherReport">
            <Row align="top" justify="start" className="forecastrow">
              <Col flex="1" offset="0" order="0" pull="0" push="0">
                <div>
                  <div className="forecastdate">
                    {`${data.Date},${data.Month}`}
                  </div>
                  <div className="forecastweeks">{translate(data.Day)}</div>
                </div>
              </Col>
              <Col flex="1" offset="0" order="1" pull="0" push="0">
                <div>
                  <img
                    alt="weatehr icon"
                    src={getIconSVGFile(data.PictoCode_Daily)}
                    className="weathericon"
                  />
                  <span className="forecasttemp">
                    <span className="weathertemphigh">
                      {Math.round(data.TempAir_DailyMax_C)}°
                    </span>
                    <span className="weathertemplow">
                      / {Math.round(data.TempAir_DailyMin_C)}&deg;C
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </AntdConfigProvider>
      );
    else
      return (
        <AntdConfigProvider>
          <div className="weatherreportselected">
            <Row align="top" justify="start" className="forecastrow">
              <Col flex="1" offset="0" order="0" pull="0" push="0">
                <div>
                  <div className="forecastdate">
                    {`${data.Date} ${data.Month}, ${getTime(
                      data.DATE_TIMESTAMP
                    )}`}
                  </div>
                  <div className="forecastweeks">{translate(data.Day)}</div>
                  <div className="forecasttodytemp">
                    <img
                      src={getHourlyForeCastIcon(
                        moment(data.DATE_TIMESTAMP).format("HH:mm"),
                        data.PictoCode_Daily
                      )}
                      className="weathericon"
                      alt="weather icon"
                    />
                    <span className="">
                      <span className="weathertemphigh m-r-18">
                        {Math.round(data.TempAir_Hourly_C)}
                        <span className="degressstyle"> &#8451;</span>
                      </span>
                      <span className="weathertemplow weathertemplowarrows">
                        &uarr;
                      </span>
                      <span className="weathertemplow m-r-18">
                        {Math.round(data.TempAir_DailyMax_C)}&deg;
                      </span>
                      <span className="weathertemplow weathertemplowarrows">
                        &darr;
                      </span>
                      <span className="weathertemplow">
                        {Math.round(data.TempAir_DailyMin_C)}&deg;
                      </span>
                    </span>
                  </div>
                  <hr></hr>
                  <AntdConfigProvider>
                    <Row align="top" justify="start" className="forecastrow">
                      <Col
                        flex="1"
                        offset="0"
                        order="0"
                        pull="0"
                        push="0"
                        className="forecastdailycol"
                      >
                        <div className="forecastdailycol">
                          <img
                            src={rain}
                            className="forecastdailyicon"
                            alt="rain"
                          />
                          <span className="spancebetween">
                            <span className="icontext">
                              {translate(ConstantKey.RAIN)}
                            </span>
                            <br />
                            <span className="icontemp">
                              {data.Precip_HourlySum_mm} mm
                            </span>
                          </span>
                        </div>
                        <div className="forecastdailycol m-l-28">
                          <img
                            src={wind}
                            className="forecastdailyicon"
                            alt="wind"
                          />
                          <span className="spancebetween">
                            <span className="icontext">
                              {translate(ConstantKey.WIND)}
                            </span>
                            <br />
                            <span className="icontemp">
                              {data.WindSpeed_Hourly_kmh} Km/h&#45;{" "}
                              <img
                                className="arrowimage"
                                alt="arrow icon"
                                src={getDirectionIcons(
                                  data.WindDirection_Hourly
                                )}
                              />
                              {data.WindDirection_Hourly}{" "}
                            </span>
                          </span>
                        </div>
                      </Col>

                      <Col
                        flex="1"
                        offset="0"
                        order="1"
                        pull="0"
                        push="0"
                        className="forecastdailycol"
                      >
                        <div className="forecastdailycol">
                          <img
                            src={humidity}
                            className="forecastdailyicon"
                            alt="forecast daily icon"
                          />
                          <span className="spancebetween">
                            <span className="icontext">
                              {translate(ConstantKey.HUMIDITY)}
                            </span>
                            <br />
                            <span className="icontemp">
                              {data.HumidityRel_Hourly_pct} %
                            </span>
                          </span>
                        </div>
                        <div className="forecastdailycol">
                          <img
                            alt="forecast daily icon"
                            src={soilmoisture}
                            className="forecastdailyicon"
                          />
                          <span className="spancebetween">
                            <span className="icontext">
                              {translate(ConstantKey.SOILMOISTURE)}
                            </span>
                            <br />
                            <span className="icontemp">
                              {data.Soilmoisture_0to10cm_Hourly_vol} Vol.%
                            </span>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </AntdConfigProvider>
                </div>
              </Col>
            </Row>
          </div>
        </AntdConfigProvider>
      );
  };
  const onClickViewMore = (): void => {
    navigator("/cw-hub-weather");
  };
  return (
    <div className="forecastalinment">
      <AntdConfigProvider>
        <div className="forecastviewmore">
          <Row align="top" justify="start" className="forecastrow">
            <Col flex="1" offset="0" order="0" pull="0" push="0">
              <div>{translate(ConstantKey.ADVANCED_FORECAST_DATA)}</div>
              <div className="forecastviewmoreinfobelow">
                {translate(ConstantKey.EVAPORTATION_HUMIDITY_AND_MORE)}
              </div>
            </Col>
            <Col
              flex="1"
              offset="0"
              order="1"
              pull="0"
              push="0"
              className="forecastviewmorebutton"
            >
              <Button type={ButtonType.primary} onClick={onClickViewMore}>
                {translate(ConstantKey.VIEW_MORE)}
              </Button>
            </Col>
          </Row>
        </div>
      </AntdConfigProvider>

      {forecastWeekly?.map((week) => displayDialyReport(week))}
    </div>
  );
};
export default ForeCastHome;
