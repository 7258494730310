import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../core/locale/strings.json";
import MyFieldLogo from "../../assets/icons/myfield-icon.svg";
import accountCircle from "../../assets/icons/account_circle_white.svg";
import emailVerifieSuccessfully from "../../assets/images/email-verifie-successfully.svg";
import CustomMenu from "../../shared/components/menu";
import { getCountryCodeFromBrowser } from "../../core/utils/common-function";
import RenderLanguageLabel from "../../shared/components/menu/RenderLanguageLabel";
import "./email-verification-success.less";
const EmailVerificationSuccess = ({ type }: { type?: string }): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const onClickEnterMyField = (): void => {
    navigate("/signin");
  };

  return (
    <div>
      <div className="email-success-container">
        <div className="email-success-content">
          <img
            src={MyFieldLogo}
            className="email-myfiled-icon"
            alt="myfieldlogo"
          />
          <div className="illustration">
            <img
              src={emailVerifieSuccessfully}
              className="email-illustration-img"
              alt="emailimage"
            />
          </div>
          <h2 className="email-expired-title ">
            {type === "success"
              ? translate(ConstantKey.EMAIL_SUCCESSFULLY_VERIFIED)
              : translate(ConstantKey.EMAIL_ALREADY_VERIFIED)}
          </h2>
          <p className="email-expired-msg">
            {type === "success"
              ? translate(ConstantKey.EMAIL_SUCCESSFULLY_MSG)
              : translate(ConstantKey.EMAIL_ALREADY_VERIFIED_MSG)}
          </p>
          <Button
            type={ButtonType.primary}
            onClick={onClickEnterMyField}
            className="email-success-enter-button"
          >
            <img
              src={accountCircle}
              className="mf-mr-10"
              alt="account circle"
            />
            {translate(ConstantKey.ENTER_MYFIELD)}
          </Button>

          <div className="email-footer flex-grow-1">
            <Link
              to={`/legal-docs?country=${getCountryCodeFromBrowser()}&tab=TC`}
              className="mf-mr-10 email-terms-btn"
              target="_blank"
            >
              {translate(ConstantKey.TERMS_AND_CONDITIONS)}
            </Link>
            <CustomMenu
              disabledOverflow
              isUsingDefaultOnSelect
              customLanguageLabel={RenderLanguageLabel}
              className="lng-selection-dropdown email-terms-btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailVerificationSuccess;
