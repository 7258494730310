import { useCallback, useState, useReducer, useEffect } from "react";
import {
  Button,
  ButtonType,
  Checkbox,
  Col,
  Progress,
  Row,
  Select,
  Tooltip,
  notification,
  Modal,
} from "syngenta-digital-cropwise-react-ui-kit";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { SignUpReduceActionTypeDTO } from "../../core/types/authentication.dto";
import { signUpReducer, initialState } from "./signUpReducer";
import GrowerApi from "../../core/api/growerAPI";
import ConstantKey from "../../core/locale/strings.json";
import {
  jobTitlesOptions,
  PASSWORD_STRENGTH_INFO,
} from "../../core/constants/constant";
import LegalDocsApi from "../../core/api/legalDocsApi";
import useForm from "../../core/hook/useForm";
import { type CountryInfoDTO } from "../../core/types/shared.dto";
import { toTitleCase } from "../../core/utils/common-function";

import CustomMenu from "../../shared/components/menu";
import RenderLanguageLabel from "../../shared/components/menu/RenderLanguageLabel";
import PasswordTooltip from "../../shared/components/passwordTooltip/passwordTooltip";

import Flags from "../../assets/flags";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import infoTooltip from "../../assets/icons/info_tooltip_icon.svg";
import fieldErrorIcon from "../../assets/icons/field-error.svg";

import "react-phone-input-2/lib/style.css";
import "./signup.less";

const SignUpAccountDetails = (): JSX.Element => {
  const { Option } = Select;
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [state, dispatch] = useReducer(signUpReducer, initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHaveContracts, setIsHaveContracts] = useState<boolean>(false);
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthColor, setPasswordStrengthColor] = useState<any>();
  const [countriesList, setCountriesList] = useState<CountryInfoDTO[]>([]);
  const [countriesCode, setCountriesCode] = useState<string[]>([]);

  const { formErrors, handleChange, handleSubmit } = useForm(initialState);

  const getDocType: Record<string, string> = {
    PN: "PRIVACY_NOTICE",
    TC: "TERMS_AND_CONDITION",
  };
  const modalErroInfo = {
    closable: false,
    title: translate(ConstantKey.MYFIELD_CONTRACT_ERROR),
    open: isHaveContracts,
    content: (
      <a className="splash-signup-link" href="mailto:support@cropwise.com">
        <span className="mf-ml-5"> {translate(ConstantKey.SUPPORT_EMAIL)}</span>
      </a>
    ),
    onOk: () => {
      setIsHaveContracts(false);
      navigate("/splash");
      window.location.reload();
    },
  };
  useEffect(() => {
    GrowerApi.getCountriesList()
      .then((resposne) => {
        if (resposne?.status === 200) {
          const res = resposne?.data?.content;
          setCountriesList(res);
          const codes = res.map((list: CountryInfoDTO) =>
            list.isoCode.toLocaleLowerCase()
          );
          setCountriesCode(codes);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    dispatch({
      type: SignUpReduceActionTypeDTO.RESET,
      payload: location.state,
    });
    dispatch({
      type: SignUpReduceActionTypeDTO.COUNTRY_CODE,
      payload: countriesList?.length ? countriesList[0]?.isoCode : "",
    });
    if (location?.state?.email?.value) {
      dispatch({
        type: SignUpReduceActionTypeDTO.EMAIL,
        payload: location.state.email.value,
      });
    }
    if (location?.state?.password?.value) {
      dispatch({
        type: SignUpReduceActionTypeDTO.PASSWORD,
        payload: location.state.password.value,
      });
      setShowPasswordField(false);
    } else {
      setShowPasswordField(true);
    }
    // making poland as default contry in signUp page.
    dispatch({
      type: SignUpReduceActionTypeDTO.COUNTRY_CODE,
      payload: "PL",
    });
  }, [location.state]);

  const signUpUser = useCallback(() => {
    handleSubmit((isValidForm) => {});
    if (state.pnAccept.value && state.tcAccept.value) {
      setIsLoading(true);
      const signUpPayload: any = {
        email: state.email.value,
        password: state.password.value,
        first_name: state.first_name.value,
        last_name: state.last_name.value,
        job_title: state.job_title.value,
        country_code: state.country_code.value,
        phone: state.phone.value,
        tcAccept: state.tcAccept.value,
        pnAccept: state.pnAccept.value,
        locale: "en",
      };
      signUpPayload.name = `${state.first_name.value} ${state.last_name.value}`;
      signUpPayload.job_title = signUpPayload.job_title
        ? signUpPayload.job_title
        : "OTHER";
      if (signUpPayload?.phone === "" || signUpPayload?.phone?.length <= 2) {
        delete signUpPayload?.phone;
      }
      // Fetching docs details
      const promise1 = LegalDocsApi.getLegalDocs(
        state.country_code.value || "PL",
        getDocType.PN
      );
      const promise2 = LegalDocsApi.getLegalDocs(
        state.country_code.value || "PL",
        getDocType.TC
      );
      const promises = [promise1, promise2];
      Promise.allSettled(promises)
        .then((response: any) => {
          if (response?.length) {
            const docsDetail: any = [];
            const detailsFailed: any = [];
            response.forEach((eachRes: any) => {
              if (eachRes?.status === "fulfilled" && eachRes?.value?.data) {
                eachRes.value.data.timeStamp = eachRes?.value?.createdOn;
                eachRes.value.data.version = "1.0.0";
                docsDetail.push(eachRes?.value?.data);
              } else {
                detailsFailed.push(eachRes);
              }
            });
            if (docsDetail.length && !detailsFailed.length) {
              signUpPayload.docs = docsDetail;
              delete signUpPayload.pnAccept;
              delete signUpPayload.tcAccept;
              GrowerApi.growerSignUp(signUpPayload)
                .then((res) => {
                  if (res?.data?.apps?.length !== 0) {
                    const response = res.data;
                    localStorage.setItem(
                      "oauth_api_response",
                      JSON.stringify(response)
                    );
                    localStorage.setItem(
                      "access_tokens",
                      response.access_token
                    );
                    localStorage.setItem(
                      "refresh_tokens",
                      response.refresh_token
                    );
                    navigate("/drop-a-pin");
                  } else {
                    setIsHaveContracts(true);
                    Modal.error(modalErroInfo);
                  }
                })
                .catch((error) => {
                  let errMessage =
                    error?.cause?.data.errorMessage || "somethig went wrong";
                  if (
                    !error?.cause?.data.errorMessage &&
                    error?.cause?.data.message
                  ) {
                    errMessage = error?.cause?.data.message;
                  }
                  notification.error({
                    message: errMessage,
                    description: "",
                    placement: "topRight",
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else {
              notification.error({
                message: "Can't able to fetch the legal docs details!",
                description: "",
                placement: "topRight",
              });
              setIsLoading(false);
            }
          }
        })
        .catch(() => {
          notification.error({
            message: "Can't able to fetch the legal docs details!",
            description: "",
            placement: "topRight",
          });
          setIsLoading(false);
        });
    } else {
      notification.error({
        message: "Accept both Terms & condtion and Privacy notice!",
        description: "",
        placement: "topRight",
      });
    }
  }, [state]);

  const onPasswordFieldValueChange = useCallback((event: any): string => {
    const passwordValue = event.target.value;
    const hasUpperCase = /[A-Z]/.test(passwordValue);
    const hasNumber = /\d/.test(passwordValue);
    handleChange("password", passwordValue);
    dispatch({
      type: SignUpReduceActionTypeDTO.PASSWORD,
      payload: passwordValue,
    });
    if (passwordValue.length >= 8) {
      if (hasUpperCase && hasNumber) {
        setPasswordStrength(100);
        setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.green);
        return translate(ConstantKey.STRONG);
      }
      if (hasUpperCase || hasNumber) {
        setPasswordStrength(75);
        setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.yellow);
        return translate(ConstantKey.FAIR);
      }
      setPasswordStrength(50);
      setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.red);
      return translate(ConstantKey.WEAK);
    } else {
      if (passwordValue.length === 0) {
        setPasswordStrength(0);
      } else {
        setPasswordStrength(30);
      }
      setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.grey);
      return translate(ConstantKey.TOO_SHORT);
    }
  }, []);

  const onFieldValueChange = useCallback(
    (event: any, actionType: SignUpReduceActionTypeDTO) => {
      switch (actionType) {
        case "FIRST_NAME":
          handleChange("first_name", event.target.value);
          dispatch({
            type: SignUpReduceActionTypeDTO.FIRST_NAME,
            payload: event.target.value,
          });
          return;
        case "LAST_NAME":
          handleChange("last_name", event.target.value);
          dispatch({
            type: SignUpReduceActionTypeDTO.LAST_NAME,
            payload: event.target.value,
          });
          return;
        case "JOB_TITLE":
          handleChange("job_title", event);
          dispatch({
            type: SignUpReduceActionTypeDTO.JOB_TITLE,
            payload: event,
          });
          return;
        case "COUNTRY_CODE":
          handleChange("country_code", event);
          dispatch({
            type: SignUpReduceActionTypeDTO.COUNTRY_CODE,
            payload: event,
          });
          return;
        case "PHONE":
          handleChange("phone", event);
          dispatch({
            type: SignUpReduceActionTypeDTO.PHONE,
            payload: event,
          });
          return;
        case "TC_ACCEPT":
          handleChange("tcAccept", event?.target?.checked);
          dispatch({
            type: SignUpReduceActionTypeDTO.TC_ACCEPT,
            payload: event?.target?.checked,
          });
          return;
        case "PN_ACCEPT":
          handleChange("pnAccept", event?.target?.checked);
          dispatch({
            type: SignUpReduceActionTypeDTO.PN_ACCEPT,
            payload: event?.target?.checked,
          });
          return;
        default:
          return state;
      }
    },
    []
  );
  const getFlatIcon = (countryCode: string): any => {
    return Flags[countryCode as keyof typeof Flags];
  };
  return (
    <Row prefixCls="signup-page">
      <Col xs={24} sm={24} md={12} prefixCls="sign-up-sec">
        <div className="sign-up-container">
          <div className="flex-grow-0">
            <img
              className="syn-field-logo"
              src={synMyFieldLogo}
              alt="Syngenta Myfield Logo"
            />
          </div>
          <div className="flex-grow-0">
            <p className="sign-up-desc">
              {translate(ConstantKey.CREATE_MYFIELD_ACCOUNT)}
            </p>
          </div>
          <div className="sign-up-sub-desc">
            {translate(ConstantKey.SIGN_UP_DESC)}
          </div>
          <div className="sign-up-form flex-grow-0">
            <div className="mf-width-100 sign-up-first-last-name">
              <div className="first-name-sec mf-pr-10 mf-pl-10">
                <p className="select-label mf-pt-10">
                  {translate(ConstantKey.FIRST_NAME)}
                </p>
                <Input
                  type="text"
                  status={formErrors?.first_name ? "error" : ""}
                  data-testid="sign-up-first-name"
                  placeholder={translate(ConstantKey.INSERT_FIRST_NAME)}
                  size="large"
                  className={`mf-accounts-input ${
                    formErrors?.first_name && "mf-error-field"
                  }`}
                  suffix={
                    formErrors?.first_name && (
                      <img src={fieldErrorIcon} alt="SignUp info tooltip" />
                    )
                  }
                  value={state.first_name.value}
                  onChange={(event) => {
                    onFieldValueChange(
                      event,
                      SignUpReduceActionTypeDTO.FIRST_NAME
                    );
                  }}
                />
                {formErrors?.first_name && (
                  <p
                    className="mf-field-error-msg"
                    data-testid="signUp-form-error-firstname"
                  >
                    {formErrors?.first_name}
                  </p>
                )}
              </div>
              <div className="second-name-sec mf-pr-10 mf-pl-10">
                <p className="select-label mf-pt-10">
                  {translate(ConstantKey.LAST_NAME)}
                </p>
                <Input
                  type="text"
                  status={formErrors?.first_name ? "error" : ""}
                  data-testid="sign-up-lastname"
                  placeholder={translate(ConstantKey.INSERT_LAST_NAME)}
                  size="large"
                  value={state.last_name.value}
                  className={`mf-accounts-input ${
                    formErrors?.last_name && "mf-error-field"
                  }`}
                  onChange={(event) => {
                    onFieldValueChange(
                      event,
                      SignUpReduceActionTypeDTO.LAST_NAME
                    );
                  }}
                  suffix={
                    formErrors?.first_name && (
                      <img src={fieldErrorIcon} alt="SignUp info tooltip" />
                    )
                  }
                />
                {formErrors?.last_name && (
                  <p
                    className="mf-field-error-msg"
                    data-testid="signUp-form-error-lastname"
                  >
                    {formErrors?.last_name}
                  </p>
                )}
              </div>
            </div>
            <div className="mf-width-100 mf-pr-10 mf-pl-10">
              <p className="select-label mf-pt-10">
                {`${translate(ConstantKey.ADD_JOB_TITLE)} (${translate(
                  ConstantKey.FIELD_OPTIONAL
                )})`}
              </p>
              <Select
                showSearch
                size="large"
                style={{ width: "100%" }}
                value={state.job_title.value}
                data-testid="sign-up-job-title"
                className="select-job-title-container"
                onChange={(event) => {
                  onFieldValueChange(
                    event,
                    SignUpReduceActionTypeDTO.JOB_TITLE
                  );
                }}
              >
                {jobTitlesOptions.map((options) => (
                  <Option
                    key={options.value}
                    value={options.value}
                    data-testid={`job-title-options-${options.label}`}
                  >
                    {options.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="mf-width-100 mf-pr-10 mf-pl-10">
              <div className="loc-res-label-sec">
                <div className="loc-res-label-left-sec">
                  <p className="select-label mf-pt-10">
                    {translate(ConstantKey.LOCATION_OF_RESIDENCE)}
                  </p>
                </div>
                <div className="loc-res-label-right-sec">
                  <Tooltip
                    placement="topRight"
                    title="We use your location to show the correct terms and notices"
                  >
                    <img src={infoTooltip} alt="SignUp info tooltip" />
                  </Tooltip>
                </div>
              </div>
              <Select
                showSearch
                size="large"
                style={{ width: "100%" }}
                defaultValue={state.country_code.value}
                value={state.country_code.value}
                className="select-job-title-container"
                data-testid="sign-up-location-of-residence"
                onChange={(event) => {
                  onFieldValueChange(
                    event,
                    SignUpReduceActionTypeDTO.COUNTRY_CODE
                  );
                }}
              >
                {countriesList.map((option: CountryInfoDTO) => (
                  <Option
                    key={option?.isoCode}
                    value={option?.isoCode}
                    data-testid={"country-options-" + option?.isoCode}
                  >
                    <div
                      className="country-options"
                      data-testid={"country-options-" + option?.isoCode}
                    >
                      <img
                        src={getFlatIcon(option?.isoCode)}
                        className="mf-cursor-pointer option-flag-image mf-mr-10"
                        alt="flag-icons"
                      />
                      <div>{toTitleCase(option.name)}</div>
                    </div>
                  </Option>
                ))}
              </Select>
              {formErrors?.country_code && (
                <p
                  className="mf-field-error-msg"
                  data-testid="signUp-form-error-countryCode"
                >
                  {formErrors?.country_code}
                </p>
              )}
            </div>
            {countriesCode.length !== 0 && (
              <div className="mf-width-100 mf-pr-10 mf-pl-10">
                <div className="mobile-number-label-sec">
                  <div className="mobile-number-label-left-sec">
                    <p className="select-label mf-pt-10">
                      {`${translate(ConstantKey.MOBILE_NUMBER)} (${translate(
                        ConstantKey.FIELD_OPTIONAL
                      )})`}
                    </p>
                  </div>
                  <div className="mobile-number-label-right-sec">
                    <Tooltip
                      placement="topRight"
                      title="We use your phone number to contact you for customer support"
                    >
                      <img src={infoTooltip} alt="SignUp info tooltip" />
                    </Tooltip>
                  </div>
                </div>
                <PhoneInput
                  onlyCountries={countriesCode}
                  country={"pl"}
                  placeholder=""
                  value={state.phone.value}
                  onChange={(event: any) => {
                    onFieldValueChange(event, SignUpReduceActionTypeDTO.PHONE);
                  }}
                  inputProps={{
                    "data-testid": "sign-up-phone",
                  }}
                />
              </div>
            )}
            {showPasswordField && (
              <div className="mf-width-100 mf-pr-10 mf-pl-10 password-container">
                <div className="password-label-container">
                  <p className="select-label mf-pt-10">
                    {translate(ConstantKey.PASSWORD)}
                  </p>
                  <Tooltip
                    title={<PasswordTooltip />}
                    data-testid="sigin-password-tooltip"
                    placement="right"
                  >
                    <div style={{ color: passwordStrengthColor?.color }}>
                      {translate(passwordStrengthColor?.message)}
                    </div>
                  </Tooltip>
                </div>
                <Input.Password
                  visibilityToggle={true}
                  status={formErrors?.password ? "error" : ""}
                  autoComplete="off"
                  type="text"
                  data-testid="sign-up-password"
                  placeholder={translate(ConstantKey.ENTER_PASSWORD)}
                  size="large"
                  value={state.password.value}
                  className={`mf-accounts-input password-input ${
                    formErrors?.password ? "mf-error-field" : ""
                  }`}
                  onChange={onPasswordFieldValueChange}
                  suffix={
                    formErrors?.password && (
                      <img src={fieldErrorIcon} alt="SignUp info tooltip" />
                    )
                  }
                />
                {formErrors?.password && (
                  <p
                    className="mf-field-error-msg"
                    data-testid="signUp-form-error-password-account-details"
                  >
                    {formErrors?.password}
                  </p>
                )}
                <Progress
                  className="password-progress-bar"
                  data-testid="password-progress-bar"
                  gapDegree={0}
                  percent={passwordStrength}
                  status="success"
                  strokeColor={passwordStrengthColor?.color}
                  strokeLinecap="round"
                  strokeWidth={6}
                  type="line"
                  showInfo={false}
                />
              </div>
            )}
            <div className="mf-width-100 mf-pr-10 mf-pl-10 mf-mb-15">
              <Checkbox
                onChange={(event) => {
                  onFieldValueChange(
                    event,
                    SignUpReduceActionTypeDTO.TC_ACCEPT
                  );
                }}
                value={state.tcAccept.value}
              >
                <div className="privacy-notice-sec">
                  {translate(ConstantKey.I_HAVE_READ_AND_ACCEPT)}{" "}
                  <Link
                    className="privacy-button-link"
                    to={`/legal-docs?country=${
                      state.country_code.value ? state.country_code.value : "PL"
                    }&tab=TC`}
                    target="_blank"
                    data-testid="term-and-condition-link"
                  >
                    {translate(ConstantKey.TERMS_AND_CONDITIONS)}
                  </Link>
                </div>
              </Checkbox>
            </div>
            <div className="mf-width-100 mf-pr-10 mf-pl-10 mf-mb-15">
              <Checkbox
                onChange={(event) => {
                  onFieldValueChange(
                    event,
                    SignUpReduceActionTypeDTO.PN_ACCEPT
                  );
                }}
                value={state.pnAccept.value}
              >
                <div className="privacy-notice-sec">
                  {translate(ConstantKey.I_HAVE_READ_AND_ACKNOWLEDGE)}{" "}
                  <Link
                    className="privacy-button-link"
                    to={`/legal-docs?country=${
                      state.country_code.value ? state.country_code.value : "PL"
                    }&tab=PN`}
                    target="_blank"
                    data-testid="privacy-notice-link"
                  >
                    {translate(ConstantKey.PRIVACY_NOTICE)}
                  </Link>
                </div>
              </Checkbox>
            </div>
            <Button
              type={ButtonType.info}
              size="large"
              loading={isLoading}
              className={"sign-up-btn"}
              data-testid="sign-up-btn"
              key="SignUpBtn"
              onClick={() => {
                signUpUser();
              }}
              disabled={isLoading}
            >
              {translate(ConstantKey.CREATE_ACCOUNT)}
            </Button>
          </div>
          <div className="lng-options flex-grow-1">
            <CustomMenu
              customLanguageLabel={RenderLanguageLabel}
              className="lng-selection-dropdown"
              disabledOverflow
              isUsingDefaultOnSelect
            />
          </div>
        </div>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={12}
        prefixCls="farm-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/farm-image-login.png)`,
        }}
      ></Col>
    </Row>
  );
};

export default SignUpAccountDetails;
