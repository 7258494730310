import type { Dispatch, FC, SetStateAction } from "react";
import type { MapboxCoordinatesDTO } from "./mapBox.dto";
import { type ItemType } from "antd/es/menu/hooks/useItems";
import { type CountryCodeKeyDTO } from "./shared.dto";

export interface CountryOptions {
  label: string;
  value: string;
}
export interface CustomMenuType {
  customLanguageLabel?: () => JSX.Element;
  dataTestId?: string;
  className?: string;
  mode?: any;
  items?: ItemType[];
  defaultSelectedKeys?: string[];
  selectedKeys?: string[];
  onSelect?: (selectedLanguage: { key: string }) => void;
  isUsingDefaultOnSelect?: boolean;
  disabledOverflow?: boolean;
  triggerSubMenuAction?: any;
}

export interface LegalDocsSidebarType {
  selectedTab: string;
  countryList: CountryOptions[];
  collapsedMenu: boolean;
  setCollapsedMenu: Dispatch<SetStateAction<boolean>>;
  SearchBar: FC<{
    countryList: CountryOptions[];
    selectedCountry: string;
    handleCountryChange: (value: string) => void;
  }>;
  selectedCountry: string;
  handleCountryChange: (value: string) => void;
  handleTabChange: (value: string) => void;
  renderLanguageLabel: () => JSX.Element;
}

export interface SearchBarType {
  countryList: CountryOptions[];
  selectedCountry: string;
  handleCountryChange: (value: string) => void;
}
export interface ProfileInitialState {
  loading: boolean;
  profileData: ProfileData;
  language: string;
  countriesData: CountryAPIProps[];
  isValidUser: boolean;
  isGrowerExist: boolean;
  userLocation: string;
  defaultOrgId: string | null;
  defaultPropertyId: string | null;
  defaultPropertyCoordinates: MapboxCoordinatesDTO;
  defaultLocation: DefaultLocationDTO | null;
  refreshUserData: boolean;
  loyalityPoints: string | null;
}

export interface DefaultLocationDTO {
  country: string;
  countryCode: string;
  city: string;
  address: string;
  location: string;
  district: string;
  latitude: number;
  longitude: number;
  locality: string;
  postcode: string;
  place: string;
  region: string;
  timeZone: string;
}

export interface ProfileData {
  authorities?: Authorites[];
  auto_created_org_id?: string;
  clock_format?: string | null;
  countryCode?: CountryCodeKeyDTO;
  created_at?: string;
  date_format?: string | null;
  default_licensing_account_id?: string;
  default_workspace_id?: string;
  deleted?: boolean;
  email?: string;
  email_verified?: boolean;
  firstName?: string;
  id: string;
  identity_provider?: string;
  is_using_rbac?: boolean;
  job_title?: string | null;
  lastName?: string;
  locale?: string;
  login?: string;
  name?: string;
  opt_ins?: OptIns[];
  pending_change_password?: boolean;
  phone?: string | null;
  photo_uri?: string;
  role?: string | null;
  roleType?: string;
  ssn?: string | null;
  tutorial_complete?: boolean;
  type?: string;
  updated_at?: string;
  userId?: string;
  growerRefId?: string;
  emailVerified?: boolean;
  emailVerificationInterval?: number;
}

export interface CountryAPIProps {
  countryRefId: string;
  isoCode: string;
  mobileCode: string;
  name: string;
}

export interface Authorites {
  context: string;
  id: string;
  permissions: Permissions[];
}

export interface Permissions {
  name: string;
  scope: string;
}

export interface OptIns {
  accepted_on: string;
  id: string;
  type: string;
}

export enum IdentityProviderForAmplitude {
  LOCAL = "LOCAL",
  SYNGENTA = "SYNGENTA",
}

export interface UserPreferencesDTO {
  agronomicEmail: boolean;
  agronomicSms: boolean;
  agronomicApp: boolean;
  commercialEmail: boolean;
  commercialApp: boolean;
}

export interface UserPreferencesReducerActionDTO {
  type: UserPreferencesTypeEnum;
  payload: any;
}

export enum UserPreferencesTypeEnum {
  AGRONOMIC_EMAIL = "AGRONOMIC_EMAIL",
  AGRONOMIC_SMS = "AGRONOMIC_SMS",
  AGRONOMIC_APP = "AGRONOMIC_APP",
  COMMERCIAL_EMAIL = "COMMERCIAL_EMAIL",
  COMMERCIAL_APP = "COMMERCIAL_APP",
  ALL = "ALL",
}
