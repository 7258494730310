import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { ApplicationState } from "../../../core/redux/reducers";
import ConstantKey from "../../../core/locale/strings.json";
import dashboardIcon from "../../../assets/icons/dashboard_icon.svg";
import mapviewIcon from "../../../assets/icons/mapview_icon.svg";
import myoffersIcon from "../../../assets/icons/myoffers_icon.svg";
import recommendationsIcon from "../../../assets/icons/recommendation_icon.svg";
import productsAndServicesIcon from "../../../assets/icons/productsandservices_icon.svg";
import contactsIcon from "../../../assets/icons/contacts_icon.svg";
import "./sidebar.less";

const CollapseSidebar = (): JSX.Element => {
  const history = useNavigate();
  const locations = useLocation();
  const { t: translate } = useTranslation();
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  const [selectedItem, setSelectedItem] = useState("" as string);

  useEffect(() => {
    const pathname = window.location.pathname?.split("/");
    if (pathname?.length >= 2) {
      setSelectedItem(pathname[1]);
    }
  }, [window.location.pathname]);

  const onClickItem = (item: string): void => {
    setSelectedItem(item);
    if (item === "createseedrecommendation") {
      if (profileDataSelector?.defaultLocation?.countryCode === "pl")
        history("/vev-page/T1");
      else if (profileDataSelector?.defaultLocation?.countryCode === "hu")
        history("/vev-page/E1");
    } else if (item === "summaryinsights") {
      history("/mapview", {
        state: { anchorId: "/mapview#insightsfromyourfarm" },
      });
    } else history(`/${item}`);
  };
  const onClickProductsAndServices = (item: string): void => {
    setSelectedItem(item);
    if (locations.pathname === "/dashboard")
      window.location.replace("/dashboard#productsandservice");
    else
      history("/dashboard", {
        state: { anchorId: "/dashboard#productsandservice" },
      });
  };
  const selectedItemStyle = (
    item: string,
    item1?: string,
    item2?: string
  ): string => {
    let itemStyle = "";
    if (
      item === selectedItem ||
      item1 === selectedItem ||
      item2 === selectedItem
    )
      itemStyle = "sidebar-selected-background";
    return itemStyle;
  };
  const selectedSubMenuStyle = (item: string): string => {
    let itemStyle = "";
    if (item === selectedItem)
      itemStyle = "collapse-submenu-selected-background";
    return itemStyle;
  };
  return (
    <div className="sidebar_collapse">
      <div
        className={`sidebar-icon-style ${selectedItemStyle("dashboard")}`}
        id="dashboard"
      >
        {selectedItem === "dashboard" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          data-testid="collapse_dashboard"
          className={`mf-btn-img ${selectedItemStyle("dashboard")}`}
          onClick={() => {
            onClickItem("dashboard");
          }}
        >
          <img src={dashboardIcon} alt="icon" className="mf-ml-15" />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.DASHBOARD)}
        </div>
      </div>
      <div className={`sidebar-icon-style ${selectedItemStyle("mapview")}`}>
        {selectedItem === "mapview" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          data-testid="collapse_mapview"
          className={`mf-btn-img ${selectedItemStyle("mapview")}`}
          onClick={() => {
            onClickItem("mapview");
          }}
        >
          <img src={mapviewIcon} alt="icon" className="mf-ml-15" />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.MAP_VIEW)}
        </div>
      </div>
      <div
        className={`sidebar-icon-style ${selectedItemStyle(
          "newoffers",
          "pre-commitments",
          "claimed-redeemed"
        )}`}
      >
        <button
          className={`mf-btn-img ${selectedItemStyle(
            "newoffers",
            "pre-commitments",
            "claimed-redeemed"
          )}`}
        >
          <img src={myoffersIcon} alt="icon" className="mf-ml-15" />
        </button>
        <div className="collapse-submenu-item collapse-submenu-submenus">
          <div className={` ${selectedSubMenuStyle("newoffers")}`}>
            {selectedItem === "newoffers" && (
              <span className="sidebar-collapse-selected-item"></span>
            )}
            <button
              data-testid="collapse_myoffers"
              className={`mf-btn-img collapse-menu-hover ${selectedItemStyle(
                "newoffers"
              )}`}
              onClick={() => {
                onClickItem("newoffers");
              }}
            >
              {translate(ConstantKey.NEW_OFFERS)}
            </button>
          </div>
          <div
            className={`mf-mt-15 ${selectedSubMenuStyle("pre-commitments")}`}
          >
            {selectedItem === "pre-commitments" && (
              <span className="sidebar-collapse-selected-item"></span>
            )}
            <button
              data-testid="collapse_commited"
              className={`mf-btn-img  collapse-menu-hover ${selectedItemStyle(
                "pre-commitments"
              )}`}
              onClick={() => {
                onClickItem("pre-commitments");
              }}
            >
              {translate(ConstantKey.COMMITED_OFFERS)}
            </button>
          </div>
          <div
            className={`mf-mt-15 ${selectedSubMenuStyle("claimed-redeemed")}`}
          >
            {selectedItem === "claimed-redeemed" && (
              <span className="sidebar-collapse-selected-item"></span>
            )}
            <button
              data-testid="collapse_claimed"
              className={`mf-btn-img collapse-menu-hover ${selectedItemStyle(
                "claimed-redeemed"
              )}`}
              onClick={() => {
                onClickItem("claimed-redeemed");
              }}
            >
              {translate(ConstantKey.CLAIMED)}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`sidebar-icon-style ${selectedItemStyle(
          "createseedrecommendation",
          "myrecommendations",
          "summaryinsights"
        )}`}
      >
        <button
          className={`mf-btn-img ${selectedItemStyle(
            "createseedrecommendation",
            "myrecommendations",
            "summaryinsights"
          )}
          `}
        >
          <img src={recommendationsIcon} alt="icon" className="mf-ml-15" />
        </button>
        <div className="collapse-submenu-item collapse-submenu-submenus">
          <div
            className={` ${selectedSubMenuStyle("createseedrecommendation")}`}
          >
            {selectedItem === "createseedrecommendation" && (
              <span className="sidebar-collapse-selected-item"></span>
            )}
            <button
              data-testid="collapse_createseedrecommendations"
              className={`mf-btn-img collapse-menu-hover ${selectedItemStyle(
                "createseedrecommendation"
              )}`}
              onClick={() => {
                onClickItem("createseedrecommendation");
              }}
            >
              {translate(ConstantKey.CREATE_NEW_RECOMMENATION)}
            </button>
          </div>
          <div
            className={`mf-mt-15 ${selectedSubMenuStyle("myrecommendations")}`}
          >
            {selectedItem === "myrecommendations" && (
              <span className="sidebar-collapse-selected-item"></span>
            )}
            <button
              data-testid="collapse_myrecommendations"
              className={`mf-btn-img collapse-menu-hover ${selectedItemStyle(
                "myrecommendations"
              )}`}
              onClick={() => {
                onClickItem("myrecommendations");
              }}
            >
              {translate(ConstantKey.MY_RECOMMEDATIONS)}
            </button>
          </div>
          <div
            className={`mf-mt-15 ${selectedSubMenuStyle("summaryinsights")}`}
          >
            {selectedItem === "summaryinsights" && (
              <span className="sidebar-collapse-selected-item"></span>
            )}
            <button
              data-testid="collapse_summaruinsights"
              className={`mf-btn-img  collapse-menu-hover ${selectedItemStyle(
                "summaryinsights"
              )}`}
              onClick={() => {
                onClickItem("summaryinsights");
              }}
            >
              {translate(ConstantKey.SUMMARY_INSIGHTS)}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`sidebar-icon-style ${selectedItemStyle(
          "products-and-services"
        )}`}
      >
        {selectedItem === "products-and-services" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          data-testid="collapse_productandservices"
          className={`mf-btn-img ${selectedItemStyle("products-and-services")}`}
          onClick={() => {
            onClickProductsAndServices("products-and-services");
          }}
        >
          <img src={productsAndServicesIcon} alt="icon" className="mf-ml-15" />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.NEW_PRODUCTS_AND_SERVICES)}
        </div>
      </div>
      <div className="sidebar-icon-style">
        {selectedItem === "contacts" && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button className="mf-btn-img" data-testid="collapse_contacts">
          <img src={contactsIcon} alt="icon" className="mf-ml-15" />
        </button>
        <div className="collapse-submenu-item popmenu">
          {translate(ConstantKey.CONTACT_SYNGENTA)}
        </div>
      </div>
    </div>
  );
};
export default CollapseSidebar;
