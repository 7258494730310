import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import ConstantKey from "../../core/locale/strings.json";

type FormErrors = Record<string, string | undefined>;

interface UseFormDTO {
  formState: any;
  formErrors: FormErrors;
  handleChange: (name: string, value: string) => void;
  handleSubmit: (callback: (isValid: boolean) => void) => void;
}

const useForm = (initialState: any): UseFormDTO => {
  const { t: translate } = useTranslation();
  const [formState, setFormState] = useState<any>(initialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const handleChange = (name: string, value: string): void => {
    setFormState({
      ...formState,
      [name]: {
        ...formState[name],
        value,
      },
    });
    validateField(name, value);
  };

  const validateField = useCallback(
    (name: string, value: any, silent = false): boolean => {
      const field = formState[name];
      field.value = value;
      let isValid = true;
      if (field.required && !field.value) {
        isValid = false;
        if (!silent) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: field.requiredErrorMessage
              ? translate(field.requiredErrorMessage)
              : translate(ConstantKey.FIELD_REQUIRED),
          }));
        }
      } else if (field.validate && !field.validate(field.value)) {
        isValid = false;
        if (!silent) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]:
              translate(field.validateMessage) ||
              translate(ConstantKey.INVALID_VALUE),
          }));
        }
      } else if (!silent) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));
      }

      return isValid;
    },
    [formState]
  );

  const validateForm = (): boolean => {
    let isFormValid = true;
    Object.keys(formState).forEach((name) => {
      const isFieldValid = validateField(name, formState[name].value);
      if (!isFieldValid) isFormValid = false;
    });
    return isFormValid;
  };

  const handleSubmit = (callback: (isValid: boolean) => void): void => {
    const isValid = validateForm();
    if (isValid) {
      callback(isValid);
    }
  };

  return {
    formState,
    formErrors,
    handleChange,
    handleSubmit,
  };
};

// Utility validation functions
const isValidEmail = (email: string): boolean => {
  const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return re.test(String(email).toLowerCase());
};

export default useForm;
export { isValidEmail };
