import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import type { ApplicationState } from "../../core/redux/reducers";
import ConstantKey from "../../core/locale/strings.json";
import { weatherCEHumUrl } from "../../core/constants/url";
import { sendAmplitudeData } from "../../core/utils/analytics";
import { expandedWeatherForecast } from "../../core/utils/analytics/constants";
import "./cw-hub-weather.less";
const CWHubWeatherPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const [loader, setLoader] = useState<boolean>(false);
  const [securityKey, setSecurityKey] = useState<null | string>(null);
  const [apiFailed, setApiFailed] = useState(null as null | boolean);

  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  useEffect(() => {
    sendAmplitudeData(expandedWeatherForecast);

    if (profileDataSelector?.profileData?.growerRefId) {
      setLoader(true);
      ProductTemplateApi.getCEHubSecurityKey(
        profileDataSelector?.profileData?.growerRefId
      )
        .then((response: { data: { encryptedText: string } }) => {
          if (response?.data?.encryptedText) {
            setApiFailed(false);
            setSecurityKey(response.data.encryptedText);
          }
        })
        .catch((e) => {
          setApiFailed(true);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, []);

  const generateCWHubURL = (): string => {
    let url = "";
    const countryCode =
      profileDataSelector?.profileData?.countryCode?.toUpperCase();
    const languageCode = localStorage.getItem("userLanguage") ?? "en";
    let lat = profileDataSelector?.defaultPropertyCoordinates?.lat;
    let lng = profileDataSelector?.defaultPropertyCoordinates?.lng;
    lat = lat && parseFloat(lat?.toFixed(5));
    lng = lng && parseFloat(lng?.toFixed(5));
    if (securityKey)
      url = weatherCEHumUrl(countryCode, languageCode, lat, lng, securityKey);
    return url;
  };
  return (
    <div className="cw-hub-weather-container">
      {loader ? (
        <div className="mf-loader" data-testid="cw-hubloader"></div>
      ) : (
        securityKey && (
          <iframe
            className="cw-hub-weather-responsive-iframe"
            title="CW Hub Weather"
            src={generateCWHubURL()}
          ></iframe>
        )
      )}
      {apiFailed && (
        <div className="cw-hub-weather-error">
          {translate(ConstantKey.SERVICE_IS_UNAVAILABLE)}
        </div>
      )}
    </div>
  );
};
export default CWHubWeatherPage;
