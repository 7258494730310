import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import ForgotPasswordFooter from "../../../pages/forgot-password/forgotPasswordFooter";
import ConstantKey from "../../../core/locale/strings.json";
import MyFieldLogo from "../../../assets/icons/myfield-icon.svg";
import emailExpiredPage from "../../../assets/images/email-expired-page.svg";
import "./linkExpiredPage.less";
const LinkExpiredPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const onClickEnterMyField = (): void => {
    navigate("/signin");
  };
  return (
    <div>
      <div className="link-expired-success-container">
        <div className="link-expired-success-content">
          <img
            src={MyFieldLogo}
            className="link-expired-myfield-logo"
            alt="myfieldlogo"
          />
          <div className="illustration">
            <img
              src={emailExpiredPage}
              className="link-expired-image-style"
              alt="emailimage"
            />
          </div>
          <h2 className="link-expired-msg">
            {translate(ConstantKey.LINK_INVALID_EXPIRED)}
          </h2>
          <p className="link-expired-desc-msg">
            {translate(ConstantKey.LINK_INVALID_EXPIRED_DESC)}
          </p>
          <Button
            type={ButtonType.primary}
            onClick={onClickEnterMyField}
            className="link-expired-button"
          >
            {translate(ConstantKey.RETURN_TO_MYFIELD)}
          </Button>
          <ForgotPasswordFooter />
        </div>
      </div>
    </div>
  );
};
export default LinkExpiredPage;
