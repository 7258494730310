import CustomMenu from "../../shared/components/menu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCountryCodeFromBrowser } from "../../core/utils/common-function";
import ConstantKey from "../../core/locale/strings.json";
import RenderLanguageLabel from "../../shared/components/menu/RenderLanguageLabel";

const ForgotPasswordFooter = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <div className="forgot-password-footer flex-grow-1">
      <Link
        to={`/legal-docs?country=${getCountryCodeFromBrowser()}&tab=TC`}
        className="mf-mr-10 forgot-password-terms-btn"
        target="_blank"
      >
        {translate(ConstantKey.TERMS_AND_CONDITIONS)}
      </Link>
      <CustomMenu
        disabledOverflow
        isUsingDefaultOnSelect
        customLanguageLabel={RenderLanguageLabel}
        className="lng-selection-dropdown forgot-password-terms-btn"
      />
    </div>
  );
};
export default ForgotPasswordFooter;
