import { useCallback, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonType,
  Col,
  Modal,
  Row,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

import ConstantKey from "../../core/locale/strings.json";
import { SignUpReduceActionTypeDTO } from "../../core/types/authentication.dto";
import GrowerApi from "../../core/api/growerAPI";
import { signUpReducer, initialState } from "./signUpReducer";
import useForm from "../../core/hook/useForm";

import CustomMenu from "../../shared/components/menu";
import RenderLanguageLabel from "../../shared/components/menu/RenderLanguageLabel";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import infoWarningIcon from "../../assets/icons/info-warning-icon.svg";

import "./signup.less";
import "react-phone-input-2/lib/style.css";

export const SignUpComponent = (): JSX.Element => {
  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  const [state, dispatch] = useReducer(signUpReducer, initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { formErrors, handleChange } = useForm(initialState);

  const continueNextStep = useCallback(() => {
    setIsLoading(true);
    GrowerApi.checkGrowerInfo(state.email.value)
      .then(() => {
        setIsOpen(true);
      })
      .catch(() => {
        navigate("/signup-password", {
          state: { email: { value: state.email.value } },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [state]);

  const redirectToLogin = useCallback(() => {
    setIsOpen(false);
    navigate("/signin", {
      state: {
        email: { value: state.email.value },
      },
    });
  }, [state]);

  const continueSignUp = useCallback(() => {
    setIsOpen(false);
  }, [state]);

  const signUpEmailOnChange = useCallback((event: any) => {
    handleChange("email", event.target.value);
    dispatch({
      type: SignUpReduceActionTypeDTO.EMAIL,
      payload: event.target.value,
    });
  }, []);

  return (
    <>
      <Modal
        className="user-exist-modal"
        cancelText={translate(ConstantKey.CANCEL)}
        title={
          <div className="new-error-modal-container">
            <img
              className="anticon"
              src={infoWarningIcon}
              alt="Info Warning Icon"
            />
            <span className="error-modal-header-message">
              {translate(ConstantKey.ALREADY_ACCOUNT_FOUND)}
            </span>
          </div>
        }
        mask
        open={isOpen}
        okText={translate(ConstantKey.SIGN_IN)}
        okType="primary"
        onCancel={continueSignUp}
        onOk={redirectToLogin}
        closable={false}
      >
        <p>{translate(ConstantKey.ALREADY_ACCOUNT_FOUND_DESC)}</p>
      </Modal>
      <Row prefixCls="signup-page">
        <Col xs={24} sm={24} md={12} prefixCls="sign-up-sec">
          <div className="sign-up-container">
            <div className="flex-grow-0">
              <img
                className="syn-field-logo"
                src={synMyFieldLogo}
                alt="Syngenta Myfield Logo"
              />
            </div>
            <div className="flex-grow-0">
              <p className="sign-up-desc">
                {translate(ConstantKey.CREATE_MYFIELD_ACCOUNT)}
              </p>
            </div>
            <div className="sign-up-form flex-grow-0">
              <div className="mf-width-100 mf-pr-10 mf-pl-10">
                <p className="select-label mf-pt-10">
                  {translate(ConstantKey.YOUR_EMAIL)}
                </p>
                <Input
                  type="default"
                  className={`mf-accounts-input ${
                    formErrors?.email ? "mf-error-field" : ""
                  }`}
                  data-testid="sign-up-email"
                  placeholder={translate(ConstantKey.ENTER_YOUR_EMAIL)}
                  size="large"
                  value={state.email.value}
                  onChange={signUpEmailOnChange}
                />
                {formErrors?.email && (
                  <p
                    className="mf-field-error-msg"
                    data-testid="signup-form-error-email"
                  >
                    {formErrors?.email}
                  </p>
                )}
              </div>
              <Button
                type={ButtonType.info}
                loading={isLoading}
                data-testid="sign-up-continue-btn"
                size="large"
                className={`sign-up-btn ${
                  !state.email ? "sign-up-btn-disabled" : ""
                }`}
                key="SignUpBtn"
                onClick={continueNextStep}
                disabled={!state.email || isLoading}
              >
                {translate(ConstantKey.CONTINUE)}
              </Button>
            </div>
            <div className="lng-options flex-grow-1">
              <CustomMenu
                isUsingDefaultOnSelect
                disabledOverflow
                customLanguageLabel={RenderLanguageLabel}
                className="lng-selection-dropdown"
              />
            </div>
          </div>
        </Col>
        <Col
          xs={0}
          sm={0}
          md={12}
          prefixCls="farm-image"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/farm-image-login.png)`,
          }}
        ></Col>
      </Row>
    </>
  );
};

export default SignUpComponent;
