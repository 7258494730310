import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import {
  Button,
  ButtonType,
  Col,
  Row,
  Progress,
  Tooltip,
} from "syngenta-digital-cropwise-react-ui-kit";
import PasswordTooltip from "../../shared/components/passwordTooltip/passwordTooltip";
import ForgotPasswordFooter from "./forgotPasswordFooter";
import ConstantKey from "../../core/locale/strings.json";
import { PASSWORD_STRENGTH_INFO } from "../../core/constants/constant";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import "./forgot-password.less";

const ForgotNewPasswordPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigator = useNavigate();
  const [firstPassword, setFirstPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState<null | boolean>(null);
  const [error, setError] = useState(false);
  const [newPasswordStatus, setNewPasswordStatus] = useState<null | string>(
    null
  );
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthColor, setPasswordStrengthColor] = useState(
    PASSWORD_STRENGTH_INFO.grey
  );
  const [loading, setLoading] = useState(false);
  const onClickChangePassword = (): void => {
    setLoading(false);
  };
  const onPasswordFieldValueChange = (event: any): string => {
    const passwordValue = event.target.value;
    const hasUpperCase = /[A-Z]/.test(passwordValue);
    const hasNumber = /\d/.test(passwordValue);
    setFirstPassword(passwordValue);
    if (passwordValue.length >= 8) {
      if (hasUpperCase && hasNumber) {
        setPasswordStrength(100);
        setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.green);
        setNewPasswordStatus(translate(ConstantKey.STRONG));
        return translate(ConstantKey.STRONG);
      } else if (hasUpperCase || hasNumber) {
        setPasswordStrength(75);
        setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.yellow);
        setNewPasswordStatus(translate(ConstantKey.FAIR));
        return translate(ConstantKey.FAIR);
      } else {
        setPasswordStrength(50);
        setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.red);
        setNewPasswordStatus(translate(ConstantKey.WEAK));

        return translate(ConstantKey.WEAK);
      }
    } else {
      if (passwordValue.length === 0) {
        setPasswordStrength(0);
      } else {
        setPasswordStrength(30);
      }
      setNewPasswordStatus(translate(ConstantKey.TOO_SHORT));

      setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.grey);
      return translate(ConstantKey.TOO_SHORT);
    }
  };
  const onBlurSecondPassword = (): void => {
    if (firstPassword !== secondPassword) setError(true);
  };
  const onClickCancel = (): void => {
    navigator("/signin");
  };
  const onChangeConformPassword = (event: any): void => {
    setSecondPassword(event.target.value);
    if (firstPassword === event.target.value) setIsValidPassword(true);
    else setIsValidPassword(false);
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={12} prefixCls="forgot-password-sec">
        <div className="forgot-password-container">
          <div className="flex-grow-0">
            <img
              className="forgot-password-field-logo flex-grow-0"
              src={synMyFieldLogo}
              alt="Syngenta Myfield Logo"
            />
          </div>
          <div className="flex-grow-0">
            <p className="forgot-password-desc">
              {translate(ConstantKey.RECOVER_PASSWORD)}
            </p>
            <p className="forgot-password-msg mf-mb-0">
              {translate(ConstantKey.TYPE_NEW_PASSWORD_BELOW)}
            </p>
          </div>
          <div className="forgot-password-form flex-grow-0">
            <div className="mf-width-100 mf-pr-10 mf-pl-10">
              <p className="forgot-password-label mf-pt-10">
                {translate(ConstantKey.NEW_PASSWORD)}
                {passwordStrengthColor?.color && (
                  <Tooltip
                    data-testid="forgot-new-password-tooltip"
                    title={<PasswordTooltip />}
                    placement="right"
                  >
                    <p style={{ color: passwordStrengthColor?.color }}>
                      {newPasswordStatus}
                    </p>
                  </Tooltip>
                )}
              </p>
              <Input.Password
                type="default"
                placeholder={translate(ConstantKey.CREATE_A_NEW_PASSWORD)}
                size="middle"
                className={`mf-accounts-input ${
                  firstPassword && "mf-error-field"
                }`}
                value={firstPassword}
                onChange={onPasswordFieldValueChange}
              />
              <Progress
                className="password-progress-bar"
                gapDegree={0}
                percent={passwordStrength}
                status="success"
                strokeColor={passwordStrengthColor.color}
                strokeLinecap="round"
                strokeWidth={6}
                type="line"
                showInfo={false}
              />
              <p className="forgot-password-label mf-pt-10">
                {translate(ConstantKey.REPEAT_PASSWORD)}
              </p>
              <Input.Password
                type="default"
                placeholder={translate(ConstantKey.CONFIRM_YOUR_NEW_PASSWORD)}
                size="middle"
                className={`mf-accounts-input ${
                  secondPassword && "mf-error-field"
                }`}
                value={secondPassword}
                onChange={onChangeConformPassword}
                onBlur={onBlurSecondPassword}
              />
              {error && (
                <p className="mf-field-error-msg">
                  {translate(ConstantKey.PASSWORDS_ARE_DIFFERENT)}
                </p>
              )}
            </div>
            <div className="forgot-new-password-buttons">
              <Button
                type={ButtonType.outline}
                key="loginBtn"
                className="forgot-buttons-wdith"
                onClick={onClickCancel}
              >
                {translate(ConstantKey.CANCEL)}
              </Button>
              <Button
                loading={loading}
                type={ButtonType.primary}
                key="loginBtn"
                className="forgot-buttons-wdith"
                onClick={onClickChangePassword}
                disabled={
                  !(firstPassword && secondPassword) && !isValidPassword
                }
              >
                {translate(ConstantKey.CHANGE_PASSWORD)}
              </Button>
            </div>
          </div>
          <ForgotPasswordFooter />
        </div>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={12}
        prefixCls="forgot-password-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/forgot-password.png)`,
        }}
      ></Col>
    </Row>
  );
};

export default ForgotNewPasswordPage;
