import { useCallback, useState, type FC } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { type ButtonProps, Dropdown } from "antd";
import {
  Avatar,
  Col,
  Divider,
  Row,
  Space,
  TopNavBar,
  Tooltip,
  Modal,
  ButtonType,
  notification,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useNavigate } from "react-router-dom";

import HeaderRightSection from "./HeaderRightSection";
import MyFieldLogo from "./MyFieldLogo";
import CustomMenu from "../../components/menu";

import ConstantKey from "../../../core/locale/strings.json";
import {
  type ProfileOverlayDTO,
  type GlobalHeaderDTO,
  type ProfileDTO,
  type DeleteAccountConfirmModalDTO,
} from "../../../core/types/headers.dto";
import { userLogout } from "../../../core/utils/analytics/constants";
import {
  clearUserProperties,
  sendAmplitudeData,
} from "../../../core/utils/analytics";
import { type ApplicationState } from "../../../core/redux/reducers";
import GrowerApi from "../../../core/api/growerAPI";

import logoutIcon from "../../../assets/icons/logout.svg";
import deleteAccountIcon from "../../../assets/icons/delete-account.svg";
import chevronRight from "../../../assets/icons/chevron_right.svg";
import openLinkNewTabIcon from "../../../assets/icons/openLinkNewTabIcon.svg";
import errorOutlineIcon from "../../../assets/icons/error_outline.svg";

import "./globalHeader.less";

type ModalButtonProps = ButtonProps & { "data-testid"?: string };

const getInitials = (name: string): string => {
  try {
    const firstName = name.split(" ")[0];
    const lastName = name.length > 0 ? name.split(" ")[1] : " ";
    return `${firstName.split("")[0]}${lastName.split("")[0]}`.toUpperCase();
  } catch {
    return "NA";
  }
};

const logout = (): void => {
  const oauthURL = process.env.REACT_APP_CROPWISE_BASE_URL;
  if (oauthURL) {
    sendAmplitudeData(userLogout);
    clearUserProperties();
    localStorage.clear();
    window.location.href = `${oauthURL}/exit?continue=${process.env.REACT_APP_URL}`;
  }
};

export const DeleteAccountConfirmModal = (
  props: DeleteAccountConfirmModalDTO
): JSX.Element => {
  const { t: translate } = useTranslation();
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const okButtonProps: ModalButtonProps = {
    "data-testid": "delete-account-ok-btn",
  };
  const cancelButtonProps: ModalButtonProps = {
    "data-testid": "delete-account-cancel-btn",
  };

  const onConfirmDeleteAccount = useCallback(() => {
    if (profileDataSelector?.profileData?.growerRefId) {
      GrowerApi.deleteAccount(profileDataSelector?.profileData?.growerRefId)
        .then(() => {
          props.setIsModalOpen(false);
          logout();
          localStorage.setItem("accountDeleted", "true");
        })
        .catch((error) => {
          notification.error({
            message: error?.cause?.data?.detail
              ? error?.cause?.data?.detail
              : `${translate(ConstantKey.SOMETHING_WENT_WRONG)}!`,
            description: "",
            placement: "topRight",
          });
        })
        .finally(() => {
          props.setIsModalOpen(false);
        });
    }
  }, []);

  const onCancelDeleteAccount = useCallback(() => {
    props.setIsModalOpen(false);
  }, []);

  return (
    <Modal
      data-testid="delete-account-confirm-modal"
      className="delete-field-confirm-modal"
      title={
        <div className="new-error-modal-container">
          <img
            className="anticon"
            src={errorOutlineIcon}
            alt="Info Warning Icon"
          />
          <span className="error-modal-header-message">
            {translate(ConstantKey.ACCOUNT_DELETION_CONFIRM_MODEL_TITLE)}
          </span>
        </div>
      }
      open={props.isModalOpen}
      cancelText={translate(ConstantKey.CANCEL)}
      onCancel={onCancelDeleteAccount}
      cancelButtonProps={cancelButtonProps}
      okText={translate(ConstantKey.CONFIRM_DELETION_BTN_TEXT)}
      okType={ButtonType.danger}
      okButtonProps={okButtonProps}
      onOk={onConfirmDeleteAccount}
      centered={true}
    >
      <p>{translate(ConstantKey.ACCOUNT_DELETION_CONFIRM_MODEL_DESC_1)}</p>
      <p>
        {translate(ConstantKey.ACCOUNT_DELETION_CONFIRM_MODEL_DESC_2)}{" "}
        <a className="splash-signup-link" href="mailto:support@cropwise.com">
          {translate(ConstantKey.SUPPORT_EMAIL)}
        </a>
      </p>
    </Modal>
  );
};

export const ProfileOverlay = (props: ProfileOverlayDTO): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const profileDataSelector = useSelector((state: any) => state.accountDetials);
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const languageLabels: Record<string, string> = {
    en: ConstantKey.ENGLISH,
    pl: ConstantKey.POLSKI,
    hu: ConstantKey.HUNGARIAN,
    ro: ConstantKey.ROMANIAN,
  };

  const renderLanguageLabel = (): JSX.Element => {
    return (
      <div className="custom-language-menu-title">
        <p className="custom-language-label">
          {translate(ConstantKey.LANGUAGE)}
        </p>
        {languageLabels[homePageState?.currentLanguage as string]}
      </div>
    );
  };

  const onClickEditProfile = (): void => {
    const userProfileUrl = `${
      process.env.REACT_APP_CROPWISE_BASE_URL ?? ""
    }/app/profile`;
    window.location.href = userProfileUrl;
  };

  const onClickPreferences = useCallback(() => {
    navigate("/preferences");
    props.setOpenProfileOverlay(false);
  }, []);

  return (
    <>
      <div data-cy="profileOverlay" className="logoutOverlayBlock">
        <div className="profile-dropdown-info">
          <h3 className="mf-pt-10 mf-px-15">
            {translate(ConstantKey.ACCOUNT)}
          </h3>
          <div className="mf-px-15 mf-pt-10">
            <Row>
              <Col span={4}>
                <Avatar shape="circle" size={40} style={{ fontSize: "12px" }}>
                  {getInitials(profileDataSelector.profileData.name)}
                </Avatar>
              </Col>
              <Col span={20}>
                <h4>
                  {profileDataSelector.profileData?.name
                    ? profileDataSelector.profileData.name
                    : "NA"}
                </h4>
              </Col>
            </Row>
          </div>
          <ul className="mf-elements-menu">
            <li className="mf-px-15">
              <Row className="edit-profile-link">
                <Col
                  span={22}
                  onClick={onClickEditProfile}
                  className="mf-mb-10"
                >
                  {translate(ConstantKey.EDIT_PROFILE)}
                </Col>
                <Col span={2} className="mf-mb-10">
                  <Tooltip
                    placement="topRight"
                    title={translate(ConstantKey.REDIRECTED_CROPWISE_ENV)}
                  >
                    <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
                  </Tooltip>
                </Col>
              </Row>
            </li>
            <Divider className="profile-navigation-divider" />
            <li className="mf-px-15">
              <Row className="profile-navigation-menu-title">
                <Col span={24}>{translate(ConstantKey.OPTIONS)}</Col>
              </Row>
              <Row className="mf-cursor-pointer">
                <Col
                  span={22}
                  onClick={onClickPreferences}
                  data-testid="preference-redirect-btn"
                >
                  {translate(ConstantKey.PREFERENCES)}
                </Col>
                <Col
                  span={2}
                  onClick={onClickPreferences}
                  data-testid="preference-redirect-btn-icon"
                >
                  <img
                    src={chevronRight}
                    alt="chevronRight"
                    className="mf-ml-5"
                  />
                </Col>
              </Row>
              <Row>
                <CustomMenu
                  customLanguageLabel={renderLanguageLabel}
                  mode="vertical"
                />
              </Row>
            </li>
            <Divider className="profile-navigation-divider" />
            <li className="mf-px-15 mf-pb-10">
              <Row>
                <Col span={22} className="mf-mt-10">
                  {translate(ConstantKey.LEAGAL_DOCUMENT)}
                </Col>
                <Col span={2} className="mf-mt-10">
                  <Tooltip
                    placement="topRight"
                    title={translate(ConstantKey.REDIRECTED_CROPWISE_ENV)}
                  >
                    <img src={openLinkNewTabIcon} alt="openLinkNewTabIcon" />
                  </Tooltip>
                </Col>
              </Row>
            </li>
          </ul>
        </div>
        <button
          className="profile-dropdown-delete-account mf-btn-img"
          data-testid="delete-account-btn"
          onClick={() => {
            setIsModalOpen(true);
            props.setOpenProfileOverlay(false);
          }}
        >
          <Space align="start" direction="horizontal" wrap={true} size="small">
            <img src={deleteAccountIcon} alt="deleteAccountIcon" />
            <h4>{translate(ConstantKey.DELETE_ACCOUNT)}</h4>
          </Space>
        </button>
        <br />
        <button
          className="profile-dropdown-logout mf-btn-img"
          data-testid="logout-btn"
          onClick={logout}
        >
          <Space align="start" direction="horizontal" wrap={true} size="small">
            <img src={logoutIcon} alt="logoutIcon" />
            <h4>{translate(ConstantKey.LOG_OUT_FROM_MYFIELD)}</h4>
          </Space>
        </button>
      </div>
      {createPortal(
        <DeleteAccountConfirmModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />,
        document.body
      )}
    </>
  );
};

export const Profile: FC<ProfileDTO> = ({ initials }) => {
  const [openProfileOverlay, setOpenProfileOverlay] = useState<boolean>(false);
  return (
    <Dropdown
      trigger={["click"]}
      overlay={<ProfileOverlay setOpenProfileOverlay={setOpenProfileOverlay} />}
      placement="topRight"
      open={openProfileOverlay}
      onOpenChange={(open) => {
        setOpenProfileOverlay(open);
      }}
    >
      <button
        className="mf-btn-img"
        data-testid="profileAvatarBtn"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Avatar shape="circle" size={40} style={{ fontSize: "14px" }}>
          {initials}
        </Avatar>
      </button>
    </Dropdown>
  );
};

export const GlobalHeader = ({
  unAuthHeader,
}: GlobalHeaderDTO): JSX.Element => {
  const profileDataSelector = useSelector((state: any) => state.accountDetials);
  return (
    <TopNavBar
      logo={<MyFieldLogo />}
      title={""}
      newDesign={true}
      userProfile={
        !unAuthHeader && (
          <Profile
            initials={getInitials(profileDataSelector.profileData.name)}
          />
        )
      }
      rightSection={!unAuthHeader && <HeaderRightSection />}
    />
  );
};
