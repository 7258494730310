import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Button,
  ButtonType,
  Col,
  Row,
} from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../core/locale/strings.json";
import ForgotPasswordFooter from "./forgotPasswordFooter";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import forgotPasswordSuccess from "../../assets/images/forgot-password-success.svg";
import "./forgot-password.less";

const ForgotPasswordSuccess = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const onClickOk = (): void => {
    navigate("/signin");
  };
  return (
    <Row data-testid="forgot-new-password-row">
      <Col
        xs={24}
        sm={24}
        md={12}
        prefixCls="forgot-password-sec"
        data-testid="forgot-new-password-col"
      >
        <div className="forgot-password-container">
          <div className="flex-grow-0">
            <img
              className="forgot-password-field-logo flex-grow-0"
              src={synMyFieldLogo}
              alt="Syngenta Myfield Logo"
            />
          </div>
          <div className="forgot-password-form flex-grow-0">
            <img
              alt="pasword success"
              src={forgotPasswordSuccess}
              className="mf-mb-10 forgot-password-image-style"
            />
            <p className="forgot-password-desc">
              {translate(ConstantKey.CHECK_YOUR_EMAIL)}
            </p>
            <p className="forgot-password-success-msg mf-mb-0">
              {translate(ConstantKey.FORGOT_PASSWORD_CHECK_EMAIL_MSG)}
            </p>
            <Button
              type={ButtonType.primary}
              key="okBtn"
              className="forgot-password-button"
              onClick={onClickOk}
            >
              {translate(ConstantKey.OK)}
            </Button>
          </div>

          <ForgotPasswordFooter />
        </div>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={12}
        prefixCls="forgot-password-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/forgot-password.png)`,
        }}
      ></Col>
    </Row>
  );
};

export default ForgotPasswordSuccess;
