import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import "./vevpage.less";

export const VEVPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const [vevUrl, setVevUrl] = useState<string>("");
  useEffect(() => {
    if (params.id) {
      ProductTemplateApi.getTemplateDetails(params?.id)
        .then((response) => {
          if (response?.data?.templateType === "vev")
            setVevUrl(response?.data?.vevUrl);
        })
        .catch((e) => {
          navigate("/page-not-found");
        });
    }
  }, [params?.id]);
  return (
    <div className="vevpage-container">
      <iframe
        className="vev-page-responsive-iframe"
        title="vev-page"
        src={vevUrl}
      ></iframe>
    </div>
  );
};

export default VEVPage;
